import axios from 'axios';
import images from 'common/images';
import { SEIGE_EXPIRE_AT, SEIGE_START_AT } from 'constants/constants';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled.div`
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
  justify-content: center;
  display: flex;
`;

const Select = styled.div`
  position: absolute;
  top: 130px;
  z-index: 100000;
`;

const Button = styled.div`
  width: 200px;
  height: 52px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 18px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: white;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

const Background = styled.img`
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
  position: absolute;
  object-fit: cover;
`;

const DinoImage = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
  z-index: 10000;
  margin: 15px;
  &:hover {
    border: 2px solid #ffffff;
    border-radius: 8px;
  }
`;

const Seige = () => {
  const [index, setIndex] = React.useState(null);
  const [expireAt, setExpireAt] = React.useState(null);
  const [eligibility, setEligibility] = React.useState(false);

  React.useEffect(() => {
    const getCampData = async () => {
      const kaikasAddress =
        localStorage.getItem('kaikasAddress') &&
        localStorage.getItem('kaikasAddress').toLowerCase();
      const data = {
        attendee: kaikasAddress,
      };
      const response = await axios.post('seige/data', data);
      setEligibility(response.data.eligibility);
      setExpireAt(response.data.expireAt);
    };

    getCampData();
  }, [setEligibility, setExpireAt]);

  return eligibility &&
    new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 &&
    new Date(SEIGE_START_AT).getTime() - new Date().getTime() < 0 ? (
    <Root>
      <Background src={images.seizeSelect} />
      <Select>
        <DinoImage
          onClick={() => setIndex(1)}
          src={index === 1 ? images.tyrannoEnabled : images.tyrannoDisabled}
        />
        <DinoImage
          onClick={() => setIndex(2)}
          src={index === 2 ? images.pteroEnabled : images.pteroDisabled}
        />
        <DinoImage
          onClick={() => setIndex(3)}
          src={index === 3 ? images.trickyEnabled : images.trickyDisabled}
        />
        <DinoImage
          onClick={() => setIndex(4)}
          src={index === 4 ? images.brachyEnabled : images.brachyDisabled}
        />
        <DinoImage
          onClick={() => setIndex(5)}
          src={index === 5 ? images.stegoEnabled : images.stegoDisabled}
        />
        {index != null ? (
          <Link
            to={{
              pathname: '/seige/participate',
              state: { type: index },
            }}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <Button>입장하기</Button>
          </Link>
        ) : null}
      </Select>
    </Root>
  ) : null;
};

export default Seige;

