// @ts-nocheck
import { v1ContractAddress, v1TestContractAddress } from 'common/contracts';
import React from 'react';
import contractAbi from '../constants/contractAbi';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ipfsImage } from 'constants/constants';
import styled from 'styled-components';
import images from 'common/images';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-top: 20px;
  max-width: 1500px;
  color: white;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 425px) {
    padding: 20px;
  }
`;

const Loading = styled.div`
  margin: 100px auto;
  width: 500px;
  color: white;
  text-align: center;
  font-size: 24px;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 18px;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: left;

  border-bottom: #353945 1.5px solid;
  padding-bottom: 40px;
  margin-bottom: 60px;

  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: white;
  padding: 10px 14px;
  justify-content: space-between;
  align-items: center;
  display: flex;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 12px;
  }
`;

const Name = styled.div`
  font-weight: 700;
`;

const Status = styled.div`
  font-size: 13px;
  color: white;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 11px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  position: relative;
  width: 50%;
  padding: 0 10px;

  @media (max-width: 425px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

const NoTrickyContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NoTricky = styled.img`
  width: 172px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const NoTrickyText = styled.div`
  margin-top: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

const AdoptText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #777e90;
  margin-top: 20px;
`;

const AdoptButton = styled.a`
  border-radius: 10px;
  padding: 10px 24px;
  width: 150px;
  margin: 50px auto 10px auto;
  background-color: transparent;
  color: white;
  font-weight: 700;
  text-align: center;
  border: white 1.5px solid;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    border: #05baad 1.5px solid;
    color: #05baad;
    text-decoration: none;
  }
`;

const MyTricky = () => {
  const [dino, setDino] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [count, setCount] = React.useState(null);
  const { t } = useTranslation();
  const kaikasAddress = localStorage.getItem('kaikasAddress');

  React.useEffect(() => {
    if (window.klaytn.networkVersion === 1001) {
      toast(t('testnet-alert'), {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, []);

  const myTrickies = React.useCallback(async () => {
    setLoading(true);
    const ids = [];
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        window.klaytn.networkVersion === 1001
          ? v1TestContractAddress
          : v1ContractAddress,
      );
    const total =
      contract && (await contract.methods.balanceOf(kaikasAddress).call());
    setCount(total);

    for (let i = 0; i < total; i++) {
      const nftId =
        contract &&
        (await contract.methods.tokenOfOwnerByIndex(kaikasAddress, i).call());
      ids.push(nftId);
    }
    const params = {
      tokenIds: ids,
    };
    const config = {
      params: params,
    };
    if (ids.length) {
      const response = await axios.get(`/dino/my-tricky`, config);
      setDino(response.data);
    } else {
      setDino([]);
    }

    setLoading(false);
  }, [window, setDino, setLoading]);

  React.useEffect(() => {
    myTrickies();
  }, [myTrickies]);

  const _status = React.useCallback(item => {
    if (item.status === 'ACTIVE') return <Status>Looking for fiance</Status>;
    else if (item.status === 'DONE') return <Status>On maternity leave</Status>;
    else return null;
  }, []);

  return loading ? (
    <Loading>Loading...</Loading>
  ) : !loading && count ? (
    <Container>
      <Title>Bride's room {`(${dino.length})`}</Title>
      <Row>
        {dino != null && dino?.length ? (
          dino?.map((item, index) => {
            return (
              <Col>
                <Link
                  key={index}
                  style={{
                    display: 'inline-block',
                    margin: '10px',
                    border: '#353945 1px solid',
                    borderRadius: 16,
                    paddingBottom: 5,
                  }}
                  to={`/list/${item.tokenId}`}
                >
                  <img
                    src={`${ipfsImage}${item.tokenId}.png`}
                    style={{ width: '100%', height: '100%', borderRadius: 16 }}
                  />
                  <Tag>
                    <Name>Cryptorus #{item.tokenId}</Name> {_status(item)}{' '}
                  </Tag>
                </Link>
              </Col>
            );
          })
        ) : (
          <NoTrickyContainer>
            <NoTricky src={images.noTricky} />
            <NoTrickyText>You don't have a Tricky yet</NoTrickyText>
            <AdoptText>Want to adopt Trickies?</AdoptText>
            <AdoptButton
              href={
                'https://opensea.io/collection/cryptorus-klay?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Species&search[stringTraits][0][values][0]=Tricky'
              }
              target="_blank"
            >
              Adopt Now
            </AdoptButton>
          </NoTrickyContainer>
        )}
      </Row>
    </Container>
  ) : (
    <Container>
      <NoTrickyContainer>
        <NoTricky src={images.noTricky} />
        <NoTrickyText>You don't have a Tricky yet</NoTrickyText>
        <AdoptText>Want to adopt Trickies?</AdoptText>
        <AdoptButton
          href={
            'https://opensea.io/collection/cryptorus-klay?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Species&search[stringTraits][0][values][0]=Tricky'
          }
          target="_blank"
        >
          Adopt Now
        </AdoptButton>
      </NoTrickyContainer>
    </Container>
  );
};

export default MyTricky;

