const ko = {
    translation: {
        "logout": "로그아웃",
        "adopt-button": "알 부화시키러 가기",
        "adopt-button2": "12월 말 오픈!",
        "need-kaikas": "를 설치해주세요",
        "send-via-kaikas": "Kaikas로 보내기",
        "kaikas-sign-in": "Kaikas 로그인",
        "kaikas-sign-in-needed": "Kaikas 확인",
        "account-change": "계정이 변경되었습니다.",
        "need-signature": "로그아웃 후 다시 로그인해주세요",
        "hero-punchline": "크립토러스 랜드에 오신 것을 환영합니다",
        "intro-title": "픽셀로 살아 돌아온 공룡들의 대장정",
        "intro": `때는 바야흐로 2021년, 기나긴 크립토 빙하기가 드디어 끝이 났고 과학자들은 5종류의 픽셀 공룡을 재현하는 데에 성공했다. \n 그리고 공룡들이 태어나면 살 수 있는 크립토러스 랜드를 만들었다. \n 이러한 뉴스에 환호한 세계 각지의 탐험가들은 랜드에 도착하여 픽셀 공룡들이 태어나는 알을 발견한다. \n 그들은 공룡들을 부화시켜 크립토러스 랜드에서 험난한 모험을 시작하게 되는데...`,
        "collection-title-first": "다섯 종류의 공룡들은",
        "collection-title-second": "각자 특별한 고유 특성을 지니고 있습니다!",
        "brachy": "브라키",
        "brachy-description": "방어",
        "brachy-count": "3,000마리",
        "brachy-details": "브라키의 고유 특성은 방어입니다. 크립토러스 랜드를 운영하는 정부는 트레저리 기금을 통해 브라키에 유동성을 공급하여 생태계에 활력을 불어넣고 생태계를 유지시키는 특성을 지니고 있습니다.",
        "stego": "스테고",
        "stego-description": "뭉치면 살고 흩어지면 죽는다",
        "stego-count": "4,000마리",
        "stego-details": "크립토러스 랜드에서 가장 개체수가 많습니다. 스테고 한 마리의 힘은 매우 약합니다. 하지만 다섯 종류의 공룡 가운데 보팅 파워의 총합계는 가장 높습니다. 즉, 많이 모이면 모일수록 스테고 왕국의 힘이 강해질 수 있다는 의미입니다.",
        "tricky": "트리키",
        "tricky-description": "브리더",
        "tricky-count": "1,600마리",
        "tricky-details": "크립토러스 랜드에서 유일한 암컷 공룡인 트리키는 나머지 4종류의 공룡들로부터 사랑 구애를 받기 바쁩니다. 유일하게 새끼 공룡을 낳을 수 있는 종류이며, 한번 새끼를 낳으면 다음 한 달간은 휴식기를 취해야 합니다.",
        "tyranno": "티라노",
        "tyranno-description": "포식자",
        "tyranno-count": "400마리",
        "tyranno-details": "크립토러스 랜드의 육식 공룡이자 먹이사슬 최상위권에 위치한 티라노는 공격력은 강하지만 개체수가 적어 다수의 적이 함께 힘을 모아 방어하면 뚫기 쉽지 않습니다. 티라노의 가장 큰 특징은 포식자로서 한 마리당 보팅 파워가 다섯 종류 가운데 가장 높습니다.",
        "ptero": "프테로",
        "ptero-description": "정찰",
        "ptero-count": "1,000마리",
        "ptero-details": "크립토러스 랜드에서 유일하게 하늘을 날 수 있는 프테로는 추후 토지 공성전에서 모든 공룡 왕국에 들어갈 수 있습니다. 미리 정보를 캐내 유리할 것 같은 팀에 미리 합류할 수 있죠. 하지만 한번 합류하면 바로 빠져나오지는 못합니다.",
        "phase1-title": "오픈",
        "phase1-description": "크립토러스 랜드로 함께 떠날 탐험가들이 모이는 날입니다. \n 홈페이지와 커뮤니티가 활성화됩니다.",
        "phase2-title": "민팅",
        "phase2-description": "크립토러스 랜드를 함께 탐험할 공룡들이 태어나는 날입니다. \n 총 10,000개의 공룡들이 태어나기 시작합니다.",
        "phase3-title": "육성",
        "phase3-description": "공룡들을 홀딩하는 기간이 늘어날수록 그에 맞는 토큰 보상을 얻을 수 있습니다.",
        "phase4-title": "교배",
        "phase4-description": "트리키와 다른 종류의 공룡 간의 교배를 통해 새끼 공룡을 낳아 키울 수 있습니다. 새로 낳은 새끼 공룡은 입양을 보낼 수도 있고, 육성하여 토큰 보상을 얻을 수도 있습니다.",
        "phase5-title": "토지 공성전",
        "phase5-description": "다섯 종류의 공룡들은 결국 성격 차이를 극복하지 못하고 따로 왕국을 만들어 살기로 결정했습니다. 그리고 더 큰 보상을 얻기 위해 서로의 땅을 차지하기 위한 공성전이 발발하게 됩니다.",
        "teams-subtitle": "크립토러스 랜드에서 공룡들을 재현하는 데에 성공한 과학자들을 소개합니다",
        "mint-button-description": "12월 30일에 공룡들이 깨어납니다!",
        "social-subtitle": "크립토러스 랜드의 다른 대원들을 만나보세요",
        "team1-intro": "NFT로 할 수 있는 더욱 재밌는 것이 무엇이 있을까 항상 고민합니다. NFT와 블록체인 기반의 보팅 기능을 통해 크립토러스 랜드라는 세계관을 만들었습니다. 크립토러스 랜드를 개발하는 다른 과학자들에게 임무를 부여하고 태스크를 관리합니다.",
        "team2-intro": "진정성있는 블록체인 프로젝트에 대한 고민을 시작으로 크립토러스 랜드 프로젝트를 시작했습니다. 유저들과 함께 성장할 수 있도록 크립토러스 랜드의 방향성을 고민하고, 운영을 담당합니다.",
        "team3-intro": "NFT를 사랑하는 소프트웨어 개발자입니다. 소프트웨어와 블록체인이 만들어나갈 건설적 미래를 믿습니다.",
        "team4-intro": "크와아아아앙.",
        "before-mint-title": "Coming soon",
        "before-mint-body": "12월 30일 오후 9시에 만나요!",
        "connect-kaikas": "Kaikas 지갑에 연결해주세요",
        "select-amount": "크립토러스를 몇 마리 입양하실까요?",
        "select-amount-desc": "1 마리 당 50 KLAY에 데려가실 수 있습니다.",
        "select-amount-kaikas": "클릭 후 Kaikas을 확인해주세요",
        "cancel": "취소하기",
        "mint-count": '민팅 횟수',
        "klaytnscope-link": 'Klaytnscope에서 확인 (반영에 약 5초 소요)',
        "klaytnscope-mintingTxHash": '민팅 트랜잭션 해시',
        "minting-failed": "민팅이 취소되거나 실패했습니다. Kaikas 잠김 여부를 확인해주시고, 추가 문의는 gm@cryptorus.land 로 연락주세요.",
        "mint-alert": 'Kaikas를 활성화 해주세요',
        "public-sale-end": '퍼블릭 세일이 종료되었습니다.',
        "adopt-now": '입양하기',
        "Enter": '입장하기',
        "kaikas-no-auth": '카이카스로 로그인해주세요',
        'testnet-alert': '테스트넷입니다',
    }
}

export default ko;