import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.css';
import MyNav from './components/MyNav';
import MyNft from './pages/MyNft';
import Mint from './pages/Mint';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { rootSaga } from './modules';
import ProtectedRoute from './components/ProtectedRoute';
import axios from 'axios';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Snapshot from 'pages/Snapshot';
import MyTricky from 'pages/MyTricky';
import List from 'pages/List';
import Breed from 'pages/Breed';
import Main from 'pages/Main';
import Map from 'pages/Map';
import V2Snapshot from 'pages/V2Snapshot';
import Fortress from 'pages/Fortress';
import Seige from 'pages/Seige';
import SeigeParticipate from 'pages/SeigeParticipate';
import SeigeResult from 'pages/SeigeResult';
import SeigeBoard from 'pages/SeigeBoard';
import SeigeItem from 'pages/SeigeItem';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

const App = () => {
  return (
    <Provider store={store}>
      <RecoilRoot>
        <BrowserRouter>
          <div>
            <MyNav />
            <Switch>
              <Route path="/map" component={Map} />
              {/* <Route path="/mint" component={Mint} exact /> */}
              <ProtectedRoute path="/fortress" component={Fortress} exact />
              <ProtectedRoute path="/seige" component={Seige} exact />
              <ProtectedRoute
                path="/seige/participate"
                component={SeigeParticipate}
                exact
              />
              <ProtectedRoute path="/seige/item" component={SeigeItem} exact />
              <ProtectedRoute
                path="/seige/result"
                component={SeigeResult}
                exact
              />
              <ProtectedRoute
                path="/seige/board"
                component={SeigeBoard}
                exact
              />
              <ProtectedRoute path="/my-nft" component={MyNft} />
              {/* <ProtectedRoute
                path="/nft/:contractAddr/:id"
                component={NftInfo}
              /> */}
              <ProtectedRoute path="/my-tricky" component={MyTricky} />
              <ProtectedRoute path="/breed/:id" component={Breed} />
              <ProtectedRoute path="/list/:tokenId" component={List} />
              <ProtectedRoute path="/snapshot" component={Snapshot} exact />
              <ProtectedRoute
                path="/v2-snapshot"
                component={V2Snapshot}
                exact
              />
              <Route path="/" component={Main} />
            </Switch>
            <ToastContainer />
          </div>
        </BrowserRouter>
      </RecoilRoot>
    </Provider>
  );
};

export default App;

