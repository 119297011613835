/* eslint-disable */
export default {
  forest: require('../assets/images/forest.png').default,
  about: require('../assets/images/about.png').default,
  benefits: require('../assets/images/benefits.png').default,
  ground: require('../assets/images/ground.png').default,
  dinos: require('../assets/images/dinos.png').default,
  brachy: require('../assets/images/brachy.png').default,
  stego: require('../assets/images/stego.png').default,
  tricky: require('../assets/images/tricky.png').default,
  tyranno: require('../assets/images/tyranno.png').default,
  ptero: require('../assets/images/ptero.png').default,
  egg: require('../assets/images/egg.png').default,
  arrowRight: require('../assets/images/arrow-right.png').default,
  roadmap: require('../assets/images/roadmap-image.png').default,
  collectionLeft: require('../assets/images/collection-left.png').default,
  collectionRight: require('../assets/images/collection-right.png').default,
  team1: require('../assets/images/team-1.png').default,
  team2: require('../assets/images/team-2.png').default,
  team3: require('../assets/images/team-3.png').default,
  team4: require('../assets/images/team-4.png').default,
  kakao: require('../assets/images/kakao.png').default,
  discord: require('../assets/images/discord.png').default,
  twitter: require('../assets/images/twitter.png').default,
  mirror: require('../assets/images/mirror.png').default,
  kakaoWhite: require('../assets/images/kakao.png').default,
  discordWhite: require('../assets/images/discord.png').default,
  twitterWhite: require('../assets/images/twitter.png').default,
  mirrorWhite: require('../assets/images/mirror.png').default,
  gifTest: require('../assets/images/dinos.gif').default,
  movingDinos: require('../assets/images/moving-dinos.gif').default,
  map: require('../assets/images/map.jpeg').default,
  bgMap: require('../assets/images/bg-map.png').default,
  noTricky: require('../assets/images/no-tricky.png').default,
  close: require('../assets/images/close.png').default,
  plusbox: require('../assets/images/plusbox.png').default,
  weddingHall: require('../assets/images/map-weddinghall.png').default,
  opensea: require('../assets/images/map-opensea.png').default,
  fortress: require('../assets/images/map-fortress.png').default,
  mapForest: require('../assets/images/map-forest.png').default,
  townhall: require('../assets/images/map-townhall.png').default,
  fortressBg: require('../assets/images/fortress-bg.png').default,
  fortressBtn: require('../assets/images/fortress-button.png').default,
  seizeSelect: require('../assets/images/seige-select.png').default,
  brachyDisabled: require('../assets/images/brachy-disabled.png').default,
  pteroDisabled: require('../assets/images/ptero-disabled.png').default,
  stegoDisabled: require('../assets/images/stego-disabled.png').default,
  trickyDisabled: require('../assets/images/tricky-disabled.png').default,
  tyrannoDisabled: require('../assets/images/tyranno-disabled.png').default,
  brachyEnabled: require('../assets/images/brachy-enabled.png').default,
  pteroEnabled: require('../assets/images/ptero-enabled.png').default,
  stegoEnabled: require('../assets/images/stego-enabled.png').default,
  trickyEnabled: require('../assets/images/tricky-enabled.png').default,
  tyrannoEnabled: require('../assets/images/tyranno-enabled.png').default,
  brachyCamp: require('../assets/images/brachy-camp.png').default,
  stegoCamp: require('../assets/images/stego-camp.png').default,
  trickyCamp: require('../assets/images/tricky-camp.png').default,
  tyrannoCamp: require('../assets/images/tyranno-camp.png').default,
  emptyCamp: require('../assets/images/empty-camp.png').default,
  boostRoyal: require('../assets/images/boost-royal.png').default,
  boostSuper: require('../assets/images/boost-super.png').default,
  boostHero: require('../assets/images/boost-hero.png').default,
  boostNo: require('../assets/images/boost-no.png').default,
};

