// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import { setKaikasAddressRoutine } from '../modules/kaikas';
import Hero from '../components/Hero';
import { connect } from 'react-redux';
import Intro from '../components/Intro';
import Collections from '../components/Collections';
import Roadmap from '../components/Roadmap';
import Teams from '../components/Teams';
import Social from '../components/Social';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { OKModal } from '../components/OKModal';
import { MintAmount } from '../components/MintAmount';
import { KaiKasModal } from '../components/Kaikas/KaiKasModal';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const NoAuth = styled.div`
  cursor: pointer;
`;

const Main = props => {
  const [kaikasAddress, setKaikasAddress] = React.useState(
    localStorage.getItem('kaikasAddress'),
  );
  const { t } = useTranslation();
  const [modal, setModal] = React.useState(false);
  const [mintAmount, setMintAmount] = React.useState(0);
  const [mintAmountModal, setMintAmountModal] = React.useState(false);
  const [kaikasModal, setKaikasModal] = React.useState(false);
  const mintRef = React.useRef(null);

  React.useEffect(() => {
    const value = props.kaikasReducer[props.setKaikasAddressRoutine.SUCCESS];
    if (value) {
      if (value.klaytnAddress) {
        setKaikasAddress(value.klaytnAddress);
      } else {
        setKaikasAddress('');
      }
    }
  }, [props.kaikasReducer, props.setKaikasAddressRoutine.SUCCESS]);

  const handleNoAuth = React.useCallback(() => {
    toast(t('kaikas-no-auth'), {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  return (
    <div>
      <Hero />
      <div ref={mintRef}></div>
      <div className="mint-button-container">
        {kaikasAddress != null ? (
          <Link to="/map" className="adopt-cta mint-button">
            {t('Enter')}
          </Link>
        ) : (
          <NoAuth className="adopt-cta mint-button" onClick={handleNoAuth}>
            {t('Enter')}
          </NoAuth>
        )}
      </div>
      <Intro />
      <Collections />
      <a
        href="https://klu.bs/pfp/0x4E12759360777CaC29Bb72494Baf034da685bED8"
        target="_blank"
        rel="noreferrer"
        className="adopt-mint-button"
      >
        <div className="adopt-text">{t('adopt-now')}</div>
      </a>
      <Roadmap />
      <Social />
      <Teams />
      <Footer />
      {modal ? (
        <OKModal
          title={t('before-mint-title')}
          body={t('before-mint-body')}
          onClose={() => setModal(false)}
          show={modal}
        />
      ) : null}
      {mintAmountModal ? (
        <MintAmount
          title={t('select-amount')}
          body={t('select-amount-desc')}
          info={t('select-amount-kaikas')}
          cancel={t('cancel')}
          onClose={() => {
            setMintAmountModal(false);
          }}
          onConfirm={setMintAmount}
          show={mintAmount}
        />
      ) : null}
      {kaikasModal ? (
        <KaiKasModal
          title={t('connect-kaikas')}
          // body={t("connect-kaikas")}
          onClose={() => setKaikasModal(false)}
          show={kaikasModal}
        />
      ) : null}
    </div>
  );
};

export default connect(
  state => ({
    ...state,
    setKaikasAddressRoutine,
  }),
  {},
)(Main);

