// @ts-nocheck
import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { v2ContractAddress, v2TestContractAddress } from 'common/contracts';
import contractAbi from '../constants/contractAbi';
import { timeDifference } from 'modules/utils';
import { BreedModal } from '../components/BreedModal';
import { ipfsImage, v2IpfsImage } from 'constants/constants';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import images from 'common/images';

const Container = styled.div`
  margin-top: 20px;
  max-width: 1500px;
  color: white;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media (max-width: 425px) {
    padding: 20px;
  }
`;

const ErrorContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorImage = styled.img`
  width: 172px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const ErrorText = styled.div`
  margin-top: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

const Button = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 16px;
  border: 1px solid #353945;
  box-sizing: border-box;
`;

const DisabledButton = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: not-allowed;
  overflow: hidden;
  outline: none;
  border-radius: 16px;
  border: 1px solid #353945;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 300px;
  border-radius: 16px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 15px;
`;

const Description = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const BreedInfo = styled.div`
  width: 650px;
  margin: auto;
  margin-top: 30px;
`;

const BreedData = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
`;

const DataTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
`;

const DataDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`;

const BreedButton = styled.button`
  height: 48px;
  width: 100%;
  left: 24px;
  top: 24px;
  border-radius: 90px;
  padding: 16px, 24px, 16px, 24px;
  background: #05baad;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
    color: white;
    background: #2dfaeb;
  }
`;

const InactiveBreedButton = styled.button`
  height: 48px;
  width: 100%;
  left: 24px;
  top: 24px;
  border-radius: 90px;
  padding: 16px, 24px, 16px, 24px;
  background: #969696;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  cursor: not-allowed !important;
`;

const ErrorSubtitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #777e90;
  margin-top: 20px;
`;

const ErrorButton = styled.a`
  border-radius: 10px;
  padding: 14px 28px;
  margin: 50px auto 10px auto;
  background-color: transparent;
  color: white;
  font-weight: 700;
  text-align: center;
  border: white 1.5px solid;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    border: #05baad 1.5px solid;
    color: #05baad;
    text-decoration: none;
  }
`;

const Breed = () => {
  const [info, setInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  const [groom, setGroom] = React.useState(null);
  const [timeLeft, setTimeLeft] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const location = useLocation();
  const [modal, setModal] = React.useState(false);
  const [mintingResult, setMintingResult] = React.useState(null);
  const [v2, setV2] = React.useState(null);

  const kaikasAddress =
    localStorage.getItem('kaikasAddress') &&
    localStorage.getItem('kaikasAddress').toLowerCase();

  const [isValid, setIsValid] = React.useState(null);

  React.useEffect(() => {
    if (window.klaytn.networkVersion === 1001) {
      toast(t('testnet-alert'), {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [])

  const getInfo = React.useCallback(async () => {
    setLoading(true);
    const link = location.pathname.split('/breed/')[1];
    const response = await axios.get(`list/breed/${link}`);
    setInfo(response.data);
    setLoading(false);
  }, [location, setLoading]);

  React.useEffect(() => {
    const max = 15 * 60;
    const startedAt = new Date(info?.expireAt);
    setTimeLeft(max);
    const interval = setInterval(() => {
      setTimeLeft(() => {
        const left = (Date.now() - startedAt) / 1000;
        if (left >= max) clearInterval(interval);
        return 0 - Math.floor(left);
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [info]);

  const handleBreed = React.useCallback(async () => {
    setModal(true);
  }, [setModal]);

  const checkValidLink = React.useCallback(async () => {
    const link = location.pathname.split('/breed/')[1];
    const data = {
      link: link,
    };
    const response = await axios.post(`/list/valid-link`, data);
    setIsValid(response.data);
    return response.data;
  }, [location]);

  React.useEffect(() => {
    getInfo();
    checkValidLink();
  }, [getInfo, checkValidLink]);

  const breeding = React.useCallback(async () => {
    const isValid = await checkValidLink();
    const minting = async () => {
      try {
        if (!kaikasAddress || kaikasAddress == null) {
          return false;
        }
        const data = window.caver.klay.abi.encodeFunctionCall(
          {
            // Smart contract function name
            name: 'mintV2',
            type: 'function',
            inputs: [
              {
                type: 'address',
                name: '_tricky',
              },
              {
                type: 'uint256',
                name: '_price',
              },
            ],
          },
          [info.trickyAddress, info.price],
        );

        let paymentTxHash;

        await window.caver.klay
          .sendTransaction({
            type: 'SMART_CONTRACT_EXECUTION',
            from: kaikasAddress,
            // NOTE - Smart contract address
            to:
              window.klaytn.networkVersion === 1001
                ? v2TestContractAddress
                : v2ContractAddress,
            value: window.caver.utils.toPeb(String(info.price), 'KLAY'),
            data,
            gas: 8000000,
          })
          .once('transactionHash', transactionHash => {
            paymentTxHash = transactionHash;
          })
          .once('receipt', async receipt => {
            setMintingResult({
              paymentTxHash: paymentTxHash,
              mintingTxHash: receipt.transactionHash,
            });

            const v2Contract =
              window.caver &&
              new window.caver.klay.Contract(
                contractAbi,
                window.klaytn.networkVersion === 1001
                  ? v2TestContractAddress
                  : v2ContractAddress,
              );

            const total =
              v2Contract &&
              (await v2Contract.methods.balanceOf(kaikasAddress).call());

            const nftId =
              v2Contract &&
              (await v2Contract.methods
                .tokenOfOwnerByIndex(kaikasAddress, total - 1)
                .call());

            setV2(nftId);

            const data = {
              tricky: info.tricky,
              list: info,
              father: groom.tokenId,
              createdBy: kaikasAddress,
              child: nftId,
              paymentId: paymentTxHash,
              txId: receipt.transactionHash,
            };

            await axios.post(`/transaction/create`, data);
            setDone(true);
          })
          .once('error', error => {
            console.log('error', error);
          });
      } catch (error) {
        toast(t('minting-failed'), {
          position: 'top-center',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log('error', error);
      }
    };
    if (
      kaikasAddress != null &&
      info?.trickyAddress !== kaikasAddress &&
      isValid &&
      info?.price > 0
    ) {
      minting();
    } else {
      toast(t('minting-failed'), {
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [groom, info, isValid, kaikasAddress, setMintingResult, setDone, setV2]);

  let time =
    info != null ? timeDifference(new Date(info?.expireAt), new Date()) : '';

  const handleBreedPress = React.useCallback(() => {
    if (groom != null) {
      breeding();
    } else {
      toast(`Please select a groom`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [groom, breeding]);

  return loading ? (
    <div
      style={{
        margin: 'auto',
        width: '300px',
        color: 'white',
        fontSize: '20px',
      }}
    >
      Loading...
    </div>
  ) : info.trickyAddress === kaikasAddress ? (
    <Container>
      <ErrorContainer>
        <ErrorImage src={images.noTricky} />
        <ErrorText>Cannot breed with the same wallet</ErrorText>
      </ErrorContainer>
    </Container>
  ) : !loading && timeLeft > 0 && time !== '' && info != null ? (
    <>
      {/* TODO: render by 1 sec */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button>
          <div>
            <Image src={`${ipfsImage}${info.tricky}.png`} />
            <Title>Bride</Title>
            <Description>Cryptorus #{info.tricky}</Description>
          </div>
        </Button>
        {!done ? (
          <Button onClick={handleBreed}>
            <div>
              {groom == null ? (
                <div>
                  <Image src={images.plusbox} />
                  <Title>Groom</Title>
                  <Description>Please Select</Description>
                </div>
              ) : (
                <div>
                  <Image src={`${ipfsImage}${groom.tokenId}.png`} />
                  <Title>Groom</Title>
                  <Description>Cryptorus #{groom.tokenId}</Description>
                </div>
              )}
            </div>
          </Button>
        ) : (
          <DisabledButton>
            <div>
              {groom == null ? (
                <div>
                  <Image src={images.plusbox} />
                  <Title>Groom</Title>
                  <Description>Please Select</Description>
                </div>
              ) : (
                <div>
                  <Image src={`${ipfsImage}${groom.tokenId}.png`} />
                  <Title>Groom</Title>
                  <Description>Cryptorus #{groom.tokenId}</Description>
                </div>
              )}
            </div>
          </DisabledButton>
        )}
      </div>
      <BreedInfo>
        <BreedData>
          <DataTitle>Fee</DataTitle>{' '}
          <DataDescription>{info.price} KLAY</DataDescription>
        </BreedData>

        {!done ? (
          <>
            <BreedData>
              <DataTitle>Time Left</DataTitle>{' '}
              <DataDescription>
                {timeLeft > 0 && time !== 0 ? time : 'Expired'}
              </DataDescription>
            </BreedData>
            {timeLeft > 0 && isValid && groom != null ? (
              <BreedButton onClick={handleBreedPress}>Breed</BreedButton>
            ) : (
              <InactiveBreedButton onClick={handleBreedPress}>
                Breed
              </InactiveBreedButton>
            )}
          </>
        ) : null}
      </BreedInfo>

      {mintingResult !== null && v2 !== null ? (
        <div className="minting-info">
          <div>
            <b>{t('klaytnscope-mintingTxHash')}</b>
          </div>
          <div>{mintingResult.mintingTxHash}</div>
          <br />
          <div>Cryptorus Baby #{v2}</div>
          <br />
          <a
            href={
              window.klaytn.networkVersion === 1001
                ? `https://testnets.opensea.io/assets/baobab/${v2TestContractAddress}/${v2}`
                : `https://opensea.io/assets/klaytn/${v2ContractAddress}/${v2}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`${v2IpfsImage}${v2}.png`}
              style={{ width: '200px', height: '200px', borderRadius: 16 }}
            />
          </a>
          <br />
          <br />
          <a
            href={
              window.klaytn.networkVersion === 1001
                ? `https://baobab.scope.klaytn.com/tx/${mintingResult.mintingTxHash}?tabId=nftTransfer`
                : `https://scope.klaytn.com/tx/${mintingResult.mintingTxHash}?tabId=nftTransfer`
            }
            target="_blank"
            rel="noreferrer"
          >
            {t('klaytnscope-link')}
          </a>
        </div>
      ) : null}
      {modal ? (
        <BreedModal
          onClose={() => setModal(false)}
          onSelect={id => setGroom(id)}
          show={modal}
        />
      ) : null}
    </>
  ) : (
    <Container>
      <ErrorContainer>
        <ErrorImage src={images.noTricky} />
        <ErrorText>Expired</ErrorText>
        <ErrorSubtitle>Find the Bride on Discord</ErrorSubtitle>
        <ErrorButton href={'https://discord.gg/cryptorus'} target="_blank">
          Visit Discord
        </ErrorButton>
      </ErrorContainer>
    </Container>
  );
};

export default Breed;

