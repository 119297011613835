import React from "react";
import images from "../common/images";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="intro-container">
        <div className="intro-title">{t("intro-title")}</div>
        <div className="intro-description">{t("intro")}</div>
        
        <div className="dinos-ground">
          {/* <div className="dinos"></div> */}
          <img alt="" src={images.movingDinos} className="moving-dinos" />
          <img alt="" src={images.ground} className="ground" />
          {/* <img alt="" src={images.ground} className="ground" /> */}
          {/* <div alt="" className="dinos"/> */}
          {/* <img alt="" src={images.ground} className="ground" /> */}
        </div>
      </div>
    </div>
  );
};

export default Intro;
