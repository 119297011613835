import images from 'common/images';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  background-color: #2998f1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
`;

const Container = styled.div`
  position: absolute;
  top: 110px;
  left: calc(50vw - 690px);
  @media (max-width: 1260px) {
    left: 20px;
  }
`;

const Background = styled.img`
  max-width: 1500px;
  width: 1400px;
  height: auto;
  position: absolute;
`;

const WeddingHall = styled.img`
  width: 150px;
  height: auto;
  left: 480px;
  top: 60px;
  position: relative;
`;

const OpenseaTag = styled.a`
  cursor: pointer;
`;

const Opensea = styled.img`
  width: 210px;
  top: 140px;
  left: -80px;
  height: auto;
  position: relative;
  @media (max-width: 381px) {
    position: absolute;
    top: 160px;
    left: 40px;
  }
`;

const Fortress = styled.img`
  width: 150px;
  top: 50px;
  left: 770px;
  height: auto;
  position: relative;
`;

const Forest = styled.img`
  width: 250px;
  top: -120px;
  left: 440px;
  height: auto;
  position: relative;
`;

const Townhall = styled.img`
  width: 150px;
  top: -270px;
  left: 1020px;
  height: auto;
  position: relative;
`;

const Div = styled.div`
  cursor: pointer;
`;

const Map = () => {
  const kaikasAddress = localStorage.getItem('kaikasAddress');
  const { t } = useTranslation();

  React.useEffect(() => {
    if (window.klaytn.networkVersion === 1001) {
      toast(t('testnet-alert'), {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, []);

  const handleNoAuth = React.useCallback(() => {
    toast(t('kaikas-no-auth'), {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const notReady = React.useCallback(() => {
    toast('Coming Soon!', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  return (
    <Root>
      <Container>
        <Background src={images.bgMap} />
        {kaikasAddress != null ? (
          <Link to="/my-tricky">
            <WeddingHall src={images.weddingHall} />
          </Link>
        ) : (
          <Div onClick={handleNoAuth}>
            <WeddingHall src={images.weddingHall} />
          </Div>
        )}
        <OpenseaTag
          target="_blank"
          href="https://opensea.io/collection/cryptorus-klay"
        >
          <Opensea src={images.opensea} />
        </OpenseaTag>
        <Div>
          <Link to="/fortress">
            <Fortress src={images.fortress} />
          </Link>
        </Div>
        <Div onClick={notReady}>
          <Townhall src={images.townhall} />
        </Div>
        <Div onClick={notReady}>
          <Forest src={images.mapForest} />
        </Div>
      </Container>
    </Root>
  );
};

export default Map;

