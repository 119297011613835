export const getBrowserLocale = () => {
  let locale =
    navigator.userLanguage ||
    navigator.language ||
    (navigator.languages && navigator.languages[0]) ||
    "en-US";
  const sliced = locale.substring(0, 2);
  if (sliced !== "en" && sliced !== "ko") {
    locale = "en";
  }
  return locale;
};

export const getSlicedBrowserLocale = () => {
  let locale =
    navigator.userLanguage ||
    navigator.language.slice(0, 2) ||
    (navigator.languages.slice(0, 2) && navigator.languages[0].slice(0, 2)) ||
    "en";
  if (locale !== "en" && locale !== "ko") {
    locale = "en";
  }
  return locale;
};
