import React from "react";
import {
  getKlipAddressFetcherRoutine,
  getKlipAddressRoutine,
  LOCAL_STORAGE_KEY_KLIP_ADDRESS,
} from "../modules/klip";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import KaikasLoginBtn from "components/Kaikas/KaikasLoginBtn";

const Login = () => {
  const klipAddr = localStorage.getItem(LOCAL_STORAGE_KEY_KLIP_ADDRESS);
  const kaikasAddress = localStorage.getItem("kaikasAddress");

  React.useEffect(() => {
    if (klipAddr && klipAddr.length > 0) {
      window.location.href = "/";
    }
    if (kaikasAddress && kaikasAddress.length > 0) {
      window.location.href = "/";
    }
  }, [klipAddr, kaikasAddress]);

  return (
    <>
      <Form>
        <Form.Group as={Row} controlId="formPlaintextPassword" className="mt-3">
          <Col className="text-center">
            <KaikasLoginBtn />
          </Col>
        </Form.Group>
      </Form>
    </>
  );
};

export default connect(
  (state) => ({
    ...state,
    getKlipAddressRoutine,
    getKlipAddressFetcherRoutine,
  }),
  {}
)(Login);
