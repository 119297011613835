import React from "react";
import { NetworkUnsupportedModal } from "../NetworkUnsupportedModal";
import { OKModal } from "../OKModal";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const kaikasAdaptor = React.forwardRef((props, ref) => {
  const [showNetworkUnsupportedModal, setShowNetworkUnsupportedModal] =
    React.useState(false);
  const [showLoginInProgressModal, setShowLoginInprogressModal] =
    React.useState(false);
  const kaikasAddress = localStorage.getItem("kaikasAddress");
  const { t } = useTranslation();

  const login = async () => {
    setShowLoginInprogressModal(true);
  };

  const onLogin = async () => {
    try {
      await window.klaytn.enable();
      // const caver = new Caver(window.klaytn)

      const kaikasAddress = window.klaytn.selectedAddress;
      if (!kaikasAddress) {
        localStorage.clear();
        console.log("kaikas address is not set.");
        window.location.reload();
      }
    } catch (err) {
      console.log("");
    }
  };

  const init = React.useCallback(async () => {
    if (!window.klaytn) return;

    window.klaytn.on("accountsChanged", async function (accounts) {
      if (kaikasAddress) {
        const klaytnAddress = window.klaytn.selectedAddress;
        if (klaytnAddress !== undefined && kaikasAddress !== klaytnAddress) {
          localStorage.clear();
          login();
        }
      }
    });

    window.klaytn.on("networkChanged", function () {
      const network = window.klaytn.networkVersion;
      // if (network !== parseInt(process.env.REACT_APP_CHAIN_ID))
      //   setShowNetworkUnsupportedModal(true);
      // else setShowNetworkUnsupportedModal(false);
    });

    if (kaikasAddress) {
      await window.klaytn.enable();

      const klaytnAddress = window.klaytn.selectedAddress;
      if (klaytnAddress !== undefined && kaikasAddress !== klaytnAddress) {
        login();
      }

      const network = window.klaytn.networkVersion;
      // if (network !== parseInt(process.env.REACT_APP_CHAIN_ID))
      //   setShowNetworkUnsupportedModal(true);
      // else setShowNetworkUnsupportedModal(false);
    }
  }, [kaikasAddress]);

  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      {showNetworkUnsupportedModal && <NetworkUnsupportedModal show={true} />}
      {showLoginInProgressModal && (
        <OKModal
          title={t('account-change')}
          body={t('need-signature')}
          onClose={onLogin}
          show={true}
        />
      )}
    </>
  );
});

export const KaikasAdaptor = connect(
  (state) => ({
    ...state,
  }),
  {}
)(kaikasAdaptor);
