import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import images from 'common/images';

const Top = styled.div`
  padding: 24px 28px;
  display: flex;
  justify-content: space-between;
  background-color: #141416;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-color: #141416;
`;

const TopText = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
`;

const TopButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

const CampButton = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 16px;
  border: 1px solid #353945;
  box-sizing: border-box;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  position: relative;
  width: 25%;
  padding: 0 10px;

  @media (max-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 16px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
`;

const SelectButton = styled.button`
  height: 60px;
  width: 95%;
  border-radius: 20px;
  font-weight: bold;
  background-color: #05baad;
  font-weight: 700;
  font-size: 20px;
  color: white;
  border: none;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

export const CampModal = props => {
  const [show, setShow] = React.useState(props.show || true);
  // @ts-ignore
  const [active, setActive] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [index, setIndex] = React.useState(null);

  const handleClose = () => {
    setShow(false);
    props.onClose && props.onClose();
  };

  const handleSelect = React.useCallback(
    id => {
      setIndex(id);
      setActive(id);
    },
    [setIndex, setActive],
  );

  const handleCampSelect = React.useCallback(() => {
    setShow(false);
    if (props.onSelect) {
      if (index === 1) props.onSelect('tyranno');
      else if (index === 2) props.onSelect('tricky');
      else if (index === 3) props.onSelect('brachy');
      else if (index === 4) props.onSelect('stego');
    }
    props.onClose && props.onClose();
  }, [index, props, setShow]);

  return (
    <Modal
      id="breed"
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Top>
        <TopText>진영 선택</TopText>
        <TopButton onClick={handleClose}>
          <img src={images.close} style={{ width: 24, height: 24 }} />
        </TopButton>
      </Top>
      <div style={{ display: 'inline-block' }}>
        <Row>
          <Col>
            <CampButton
              key={index}
              style={
                active === 1
                  ? {
                      border: 'white 2px solid',
                    }
                  : {
                      border: 'transparent 2px solid',
                    }
              }
              onClick={() => handleSelect(1)}
            >
              <Image src={images.tyrannoCamp} />
              <Title>티라노</Title>
            </CampButton>
          </Col>
          <Col>
            <CampButton
              key={index}
              style={
                active === 2
                  ? {
                      border: 'white 2px solid',
                    }
                  : {
                      border: 'transparent 2px solid',
                    }
              }
              onClick={() => handleSelect(2)}
            >
              <Image src={images.trickyCamp} />
              <Title>트리키</Title>
            </CampButton>
          </Col>
          <Col>
            <CampButton
              key={index}
              style={
                active === 3
                  ? {
                      border: 'white 2px solid',
                    }
                  : {
                      border: 'transparent 2px solid',
                    }
              }
              onClick={() => handleSelect(3)}
            >
              <Image src={images.brachyCamp} />
              <Title>브라키</Title>
            </CampButton>
          </Col>
          <Col>
            <CampButton
              key={index}
              style={
                active === 4
                  ? {
                      border: 'white 2px solid',
                    }
                  : {
                      border: 'transparent 2px solid',
                    }
              }
              onClick={() => handleSelect(4)}
            >
              <Image src={images.stegoCamp} />
              <Title>스테고</Title>
            </CampButton>
          </Col>
        </Row>
      </div>

      <Modal.Footer
        style={{ justifyContent: 'center' }}
        className="text-center"
      >
        <SelectButton onClick={() => handleCampSelect()}>선택하기</SelectButton>
      </Modal.Footer>
    </Modal>
  );
};

