import React from "react";
import images from "../common/images";
// import { useTranslation } from "react-i18next";

const Footer = () => {
  // const { t } = useTranslation();
  return (
    <div className="section-top footer">
      <hr className="footer-divider" />
      <div className="footer-items">
        <div>
          <div className="footer-email-title">Cryptorus</div>
          <div>
            <a className="footer-email" href="mailto:gm@cryptorus.land">
              gm@cryptorus.land
            </a>
          </div>
        </div>
        <div>
          <div className="social">
            <a href="https://open.kakao.com/o/gWHLjZMd" className="social-icon" rel="noreferrer" target="_blank">
              <img alt="kakao" src={images.kakao} className="social-image" />
            </a>
            <a href="https://discord.gg/GeeuGknEsN" className="social-icon" rel="noreferrer" target="_blank">
              <img alt="discord" src={images.discord} className="social-image" />
            </a>
            <a href="https://twitter.com/Cryptorus_Land" className="social-icon" rel="noreferrer" target="_blank">
              <img alt="twitter" src={images.twitter} className="social-image" />
            </a>
            <a href="https://mirror.xyz/cryptorusland.eth" className="social-icon" rel="noreferrer" target="_blank">
              <img alt="twitter" src={images.mirror} className="social-image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
