import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TimeAgo from "javascript-time-ago";

import ko from "javascript-time-ago/locale/ko";
import en from "javascript-time-ago/locale/en";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import i18n from "./i18n";
import { getSlicedBrowserLocale } from "./locale";

const lang = localStorage.getItem("lang");

const locale = lang?.length && lang !== null ? lang : getSlicedBrowserLocale();

i18n.changeLanguage(locale);

TimeAgo.addDefaultLocale(ko);
TimeAgo.addLocale(en);

ReactDOM.render(<App />, document.getElementById("root"));
