import React from "react";
import images from "../common/images";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import spectCollection from "../common/spec-collections";
import { useRecoilValue } from "recoil";
import langState from "../atom/langState";

const Collections = () => {
  const [index, setIndex] = React.useState(0);
  const lang = useRecoilValue(langState);
  const { t } = useTranslation();

  const _collection = React.useMemo(() => {
    if (!lang) console.log('Language is null');
    return (
      <div className="spec">
        {/* <div className="specs">SPECS</div> */}
        <div className="spec-title">{t(`${spectCollection[index]}`)}</div>
        <hr className="spec-divider" />
        <div className="spec-subtitle">
          <div className="spec-nickname">
            {t(`${spectCollection[index]}-description`)}
          </div>
          <div className="spec-count">
            {t(`${spectCollection[index]}-count`)}
          </div>
        </div>
        <div className="spec-description">
          {t(`${spectCollection[index]}-details`)}
        </div>
      </div>
    );
  }, [index, lang, t]);

  return (
    <div className="section-top">
      <Row>
        <Col lg={6} xs={12}>
          <div className="collection-title-container">
            <div className="collection-title">
              <div className="collection-title-first">
                {t("collection-title-first")}
              </div>
              <div className="collection-title-second">
                {t("collection-title-second")}
              </div>
            </div>
            <img
              alt=""
              src={images[`${spectCollection[index]}`]}
              className="dino-image"
            />
          </div>
        </Col>
        <Col lg={6} xs={12}>
          {_collection}
          <div className="spec-collection-arrows">
            <div
              onClick={() =>
                setIndex((prev) => {
                  if (prev > 0) return prev - 1;
                  else return prev;
                })
              }
              className="collection-arrow-left"
            >
              <img alt="" src={images.collectionLeft} />
            </div>
            <div
              onClick={() =>
                setIndex((prev) => {
                  if (prev < 4) return prev + 1;
                  else return prev;
                })
              }
              className="collection-arrow-right"
            >
              <img alt="" src={images.collectionRight} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Collections;
