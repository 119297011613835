export const v1ContractAddress = '0xf556116c62203424fa0c0c659cadcdd8d9d07675';

export const v1TestContractAddress =
  '0x030989ef85f8f2f7a41f081d8eac41f20e525155';

export const v2ContractAddress = '0xe920968566BC9aDe3166ff080ef2dB539636E5D2';

export const v2TestContractAddress =
  '0x62042265F973e2fEade8ED358c23C83F98cbc0bb';

export const seigeContractAddress =
  '0xE6d4f1daBB6aedCbBeA666DE41cD1680068EDC82';

export const seigeTestContractAddress =
  '0x39dd6Db102D7E9ada4F36b72976D06a03F457197';

export const kwangBoostAddress = '0x7a282a132b76ac6f769af037f92978a7c978a382';

export const ITEM_ADDRESS = '0xe1d8aa55565de2f44fd62402de1d2423c72202b5';

