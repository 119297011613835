// @ts-nocheck
import React from 'react';
import reactCSS from 'reactcss';
// import axios from "axios";
// import { LOCAL_STORAGE_KEY_KLIP_ADDRESS } from "../modules/klip";
import { setKaikasAddressRoutine } from '../modules/kaikas';
import colors from '../common/colors';
import Hero from '../components/Hero';
import { connect } from 'react-redux';
import Intro from '../components/Intro';
import Collections from '../components/Collections';
import Roadmap from '../components/Roadmap';
import Teams from '../components/Teams';
import Social from '../components/Social';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { OKModal } from '../components/OKModal';
import { MintAmount } from '../components/MintAmount';
import { KaiKasModal } from '../components/Kaikas/KaiKasModal';
import contractAbi from '../constants/contractAbi';
import { toast } from 'react-toastify';
import { v1ContractAddress, v1TestContractAddress} from '../common/contracts';

const styles = reactCSS({
  default: {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: colors.black,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    hash: {
      wordBreaK: 'break-all',
      marginBottom: '20px',
      fontSize: '18px',
    },
    form: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.background,
    },
  },
});

const Mint = props => {
  const [kaikasAddress, setKaikasAddress] = React.useState(
    localStorage.getItem('kaikasAddress'),
  );
  const [mintingResult, setMintingResult] = React.useState(null);
  const { t } = useTranslation();
  const [mintingHover, setMintingHover] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [mintAmount, setMintAmount] = React.useState(0);
  const [mintAmountModal, setMintAmountModal] = React.useState(false);
  const [kaikasModal, setKaikasModal] = React.useState(false);
  const [mintingcount, setMintingCount] = React.useState(null);
  const mintRef = React.useRef(null);

  React.useEffect(() => {
    const value = props.kaikasReducer[props.setKaikasAddressRoutine.SUCCESS];
    if (value) {
      if (value.klaytnAddress) {
        setKaikasAddress(value.klaytnAddress);
      } else {
        setKaikasAddress('');
      }
    }
  }, [props.kaikasReducer, props.setKaikasAddressRoutine.SUCCESS]);

  const getCount = React.useCallback(async () => {
    // NOTE - Smart contract address
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        window.klaytn.networkVersion === 1001
          ? v1TestContractAddress
          : v1ContractAddress,
      );
    const value = contract && (await contract.methods.mintingCount().call());
    value && setMintingCount(Number(value));
  }, [setMintingCount]);

  React.useEffect(() => {
    getCount();
  }, [getCount]);

  // Mint announcement modal
  const beforeMint = () => setModal(true);

  // Calling a smart contract from the frontend
  const mint = React.useCallback(async () => {
    let address = kaikasAddress;
    executeScroll();
    if (!address) {
      setKaikasModal(true);
      return false;
    }
    setMintAmountModal(true);
  }, [setKaikasModal, setMintAmountModal, kaikasAddress]);

  React.useEffect(() => {
    const minting = async () => {
      try {
        let address = kaikasAddress;

        if (!address || address == null) {
          setKaikasModal(true);
          return false;
        }

        const data = window.caver.klay.abi.encodeFunctionCall(
          {
            // Smart contract function name
            name: 'mintCryptorus',
            type: 'function',
            inputs: [
              {
                type: 'uint256',
                name: 'count',
              },
            ],
          },
          [mintAmount],
        );

        let paymentTxHash;
        await window.caver.klay
          .sendTransaction({
            type: 'SMART_CONTRACT_EXECUTION',
            from: address,
            // NOTE - Smart contract address
            to: window.klaytn.networkVersion === 1001
            ? v1TestContractAddress
            : v1ContractAddress,
            // Minting price * mintAmount
            value: window.caver.utils.toPeb(String(0 * mintAmount), 'KLAY'),
            data,
            gas: 8000000,
          })
          .once('transactionHash', transactionHash => {
            paymentTxHash = transactionHash;
          })
          .once('receipt', receipt => {
            console.log('receipt', receipt);
            setMintingResult({
              paymentTxHash: paymentTxHash,
              mintingTxHash: receipt.transactionHash,
            });
            setMintAmount(0);
            setMintAmountModal(false);
            getCount();
          })
          .once('error', error => {
            console.log('error', error);
            setMintAmount(0);
            setMintAmountModal(false);
            getCount();
          });
      } catch (error) {
        toast(t('minting-failed'), {
          position: 'top-center',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log('error', error);
        setMintAmount(0);
        setMintAmountModal(false);
        getCount();
      }
    };
    if (mintAmount != null && mintAmount !== 0 && kaikasAddress != null) {
      minting();
    }
  }, [
    mintAmount,
    kaikasAddress,
    setMintingResult,
    setMintAmount,
    setMintAmountModal,
  ]);

  const executeScroll = React.useCallback(() => {
    mintRef?.current?.scrollIntoView();
  }, [mintRef]);

  return (
    <div>
      <Hero />
      <div ref={mintRef}>
        {/* {mintingcount != null ? (
          <>
            <div className="mint-count-title">{t("mint-count")}</div>
            <div className="mint-button-description">
              {`${numberWithCommas(mintingcount)} / ${numberWithCommas(9300)}`}
            </div>
          </>
        ) : null} */}
      </div>
      <div className="end-mint">{t('public-sale-end')}</div>
      <div className="mint-button-container" onClick={() => mint()}>
        <a
          // href="https://klu.bs/pfp/0x4E12759360777CaC29Bb72494Baf034da685bED8"
          target="_blank"
          rel="noreferrer"
          className="adopt-cta mint-button"
        >
          {t('adopt-now')}
        </a>
        <a
          href="https://mirror.xyz/cryptorusland.eth/TUzw7TExXX2ZzhrEwP0bR-cFX3EeauuYJwBt6xrMTys"
          target="_blank"
          rel="noreferrer"
          className="primary-text learn-more-button"
        >
          Learn more
        </a>
      </div>
      {mintingResult !== null ? (
        <div className="minting-info">
          <div style={styles.hash}>
            <b>{t('klaytnscope-mintingTxHash')}</b>
          </div>
          <div>{mintingResult.mintingTxHash}</div>
          <br />
          <a
            href={
              window.klaytn.networkVersion === 1001
                ? `https://baobab.scope.klaytn.com/tx/${mintingResult.mintingTxHash}?tabId=nftTransfer`
                : `https://scope.klaytn.com/tx/${mintingResult.mintingTxHash}?tabId=nftTransfer`
            }
            target="_blank"
            rel="noreferrer"
          >
            {t('klaytnscope-link')}
          </a>
        </div>
      ) : null}
      <Intro />
      <Collections />
      <a
        href="https://klu.bs/pfp/0x4E12759360777CaC29Bb72494Baf034da685bED8"
        target="_blank"
        rel="noreferrer"
        className="adopt-mint-button"
      >
        <div className="adopt-text">{t('adopt-now')}</div>
      </a>
      <Roadmap />
      <Social />
      <Teams />
      <Footer />
      {modal ? (
        <OKModal
          title={t('before-mint-title')}
          body={t('before-mint-body')}
          onClose={() => setModal(false)}
          show={modal}
        />
      ) : null}
      {mintAmountModal ? (
        <MintAmount
          title={t('select-amount')}
          body={t('select-amount-desc')}
          info={t('select-amount-kaikas')}
          cancel={t('cancel')}
          onClose={() => {
            setMintAmountModal(false);
          }}
          onConfirm={setMintAmount}
          show={mintAmount}
        />
      ) : null}
      {kaikasModal ? (
        <KaiKasModal
          title={t('connect-kaikas')}
          // body={t("connect-kaikas")}
          onClose={() => setKaikasModal(false)}
          show={kaikasModal}
        />
      ) : null}
    </div>
  );
};

export default connect(
  state => ({
    ...state,
    setKaikasAddressRoutine,
  }),
  {},
)(Mint);
