export function createDefaultReducer(routines) {
  var reducers = {};
  routines.forEach( x => {
    if(x.reducer !== false) {
      reducers = Object.assign(reducers, {
        [x.routine.SUCCESS]: (state, action) => {
          return {...state, [x.routine.SUCCESS]:action.payload};
        },
        [x.routine.FAILURE]: (state, action) => {
          return {...state, [x.routine.FAILURE]:action.payload};
        },
        [x.routine.FULFILL]: (state, action) => {
          return {...state, [x.routine.FULFILL]:action.payload};
        }
      });
    }
  })
  return reducers;
}

export const isMobile = {
  Android: function () {
           return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  BlackBerry: function () {
           return navigator.userAgent.match(/BlackBerry/i) == null ? false : true;
  },
  IOS: function () {
           return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
  },
  Opera: function () {
           return navigator.userAgent.match(/Opera Mini/i) == null ? false : true;
  },
  Windows: function () {
           return navigator.userAgent.match(/IEMobile/i) == null ? false : true;
  },
  any: function () {
           return (isMobile.Android() || isMobile.BlackBerry() || isMobile.IOS() || isMobile.Opera() || isMobile.Windows());
  }
};

export function setHttp(link) {
  let result = link;
  if (link.indexOf('http://') != -1) {
    result = link.replace('http://', 'https://');
  }
  if (link.search(/^http[s]?\:\/\//) == -1) {
    result = link = 'https://' + link;
  }
  return result;
}

export function removeHttp(url) {
  return url.replace(/^https?:\/\//, '');
}


export function timeDifferenceDate(date1, date2) {
  let difference = date1.getTime() - date2.getTime();
  if (Math.abs(difference) < 2000) {
    return '';
  }

  const yearsDifference = Math.floor(difference / 1000 / 60 / 60 / 24 / 365);
  difference -= yearsDifference * 1000 * 60 * 60 * 24 * 365;

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  if (yearsDifference < 0 || daysDifference < 0 || hoursDifference < 0) {
    return '';
  }

  const dayString =
    daysDifference === 0
      ? ''
      : daysDifference === 1
      ? `${daysDifference} day`
      : `${daysDifference} days`;

  const hourString =
    hoursDifference === 0
      ? ''
      : hoursDifference === 1
      ? `${hoursDifference} hr`
      : `${hoursDifference} hrs`;

  const minuteString =
    minutesDifference === 0
      ? ''
      : minutesDifference === 1
      ? `${minutesDifference} min`
      : `${minutesDifference} mins`;

  const secondDifference = Math.floor(difference / 1000);
    difference -= minutesDifference / 1000;

  const secondString =
  secondDifference === 0
      ? ''
      : secondDifference === 1
      ? `${secondDifference} sec`
      : `${secondDifference} secs`;

  if (
    daysDifference === 0 &&
    hoursDifference === 0 &&
    minutesDifference === 0
  ) {
    return secondDifference + ' secs';
  }

  const timeFormat = [];
  if (daysDifference !== 0) timeFormat.push(`${dayString}`);
  if (hoursDifference !== 0) timeFormat.push(`${hourString}`);
  if (minutesDifference !== 0) timeFormat.push(`${minuteString}`);
  if (secondDifference !== 0) timeFormat.push(`${secondString}`);

  const time = timeFormat.join(' ');
  return time;
}

export function timeDifferenceSeige(date1, date2) {
  let difference = date1.getTime() - date2.getTime();
  if (Math.abs(difference) < 10000) {
    return '1분 내로 종료됩니다';
  }

  const yearsDifference = Math.floor(difference / 1000 / 60 / 60 / 24 / 365);
  difference -= yearsDifference * 1000 * 60 * 60 * 24 * 365;

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  if (yearsDifference < 0 || daysDifference < 0 || hoursDifference < 0) {
    return '';
  }

  const dayString =
    daysDifference === 0
      ? ''
      : `${daysDifference} D`;

  const hourString =
    hoursDifference === 0
      ? ''
      : `${hoursDifference} H`;

  const minuteString =
    minutesDifference === 0
      ? ''
      : `${minutesDifference} m`;

  const timeFormat = [];
  if (daysDifference !== 0) timeFormat.push(`${dayString}`);
  if (hoursDifference !== 0) timeFormat.push(`${hourString}`);
  if (minutesDifference !== 0) timeFormat.push(`${minuteString}`);

  const time = timeFormat.join(' : ');
  return time;
}

export function timeDifference(date1, date2) {
  let difference = date1.getTime() - date2.getTime();

  if (Math.abs(difference) < 2000 || difference < 0) {
    return '';
  }

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondDifference = Math.floor(difference / 1000);
  difference -= minutesDifference / 1000;

  function zeros(i) {
    if (i < 10) {
      return "0" + i;
    } else {
      return i;
    }
  }

  const dayString =
    daysDifference === 0
      ? ''
      : `${zeros(daysDifference)}`;

  const hourString =
    hoursDifference === 0
      ? ''
      : `${zeros(hoursDifference)}`;

  const minuteString =
    minutesDifference === 0
      ? '00'
      : `${zeros(minutesDifference)}`;

  const secondString =
    secondDifference === 0
      ? '00'
      : `${zeros(secondDifference)}`;

  if (
    daysDifference === 0 &&
    hoursDifference === 0 &&
    minutesDifference === 0
  ) {
    return secondString + ' secs';
  }

  const timeFormat = [];
  if (daysDifference !== 0) timeFormat.push(`${dayString}`);
  if (hoursDifference !== 0) timeFormat.push(`${hourString}`);
  timeFormat.push(`${minuteString}`);
  timeFormat.push(`${secondString}`);

  const time = timeFormat.join(':');
  return time;
}

export function isInt(n) {
  return n % 1 === 0;
}