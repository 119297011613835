// @ts-nocheck
import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import contractAbi from '../constants/contractAbi';
import { v1ContractAddress, v1TestContractAddress } from 'common/contracts';
import { BreedOptionModal } from 'components/BreedOptionModal';
import { ipfsImage } from 'constants/constants';
import { timeDifference, timeDifferenceDate } from 'modules/utils';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import images from 'common/images';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-top: 20px;
  max-width: 1500px;
  color: white;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media (max-width: 500px) {
    padding: 20px;
    display: block
  }
`;

const Profile = styled.div`
  margin-left: 20px;
  width: 40%;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
`;

const MetaData = styled.div`
  width: 55%;

  @media (max-width: 500px) {
    margin-top: 50px;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 80%;
  border-radius: 16px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ProfileInfo = styled.div`
  margin-top: 35px;
  border-radius: 16px;
  background-color: #23262f;
  padding: 24px;
  border: 1px solid #353945;
  box-shadow: 0px 64px 64px -48px #1f2f461f;
  width: 80%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ProfileButton = styled.button`
  height: 48px;
  width: 100%;
  left: 24px;
  top: 24px;
  border-radius: 90px;
  padding: 16px, 24px, 16px, 24px;
  background: #05baad;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    color: white;
    background: #2dfaeb;
  }
`;

const BreedLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

const MetaDataHeader = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #05baad;
  margin-bottom: 10px;
`;

const TokenId = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
  margin-bottom: 35px;
`;

const BreedInfo = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const BreedInfoContainer = styled.div`
  width: 40%;
`;

const BreedInfoTitle = styled.div`
  color: #777e90;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

const BreedData = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const TableContainer = styled.div``;

const Table = styled.table`
  width: 100%;
  border: 1px solid #353945;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background: #353945;
`;

const TableRow = styled.tr``;

const HeaderLeft = styled.td`
  padding: 12px 16px;
  color: white;
  font-weight: 700;
`;

const TableLeft = styled.td`
  padding: 12px 16px;
  color: #777e90;
  font-weight: 400;
  width: 35%;
`;

const TableRight = styled.td`
  padding: 12px 16px;
  color: white;
  font-weight: 700;
  word-break: break-all;
  width: 65%;
`;

const NotOwnerContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotOwner = styled.img`
  width: 172px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const NotOwnerText = styled.div`
  margin-top: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

const List = () => {
  const [dino, setDino] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [listInfo, setListInfo] = React.useState(null);
  const [owner, setOwner] = React.useState(null);
  const [attributes, setAttributes] = React.useState(null);
  const [link, setLink] = React.useState('');
  const [price, setPrice] = React.useState(30);
  const [modal, setModal] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(100);
  const [pending, setPending] = React.useState(false);
  const [history, setHistory] = React.useState(null);
  let location = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (window.klaytn.networkVersion === 1001) {
      toast(t('testnet-alert'), {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [])

  const kaikasAddress =
    localStorage.getItem('kaikasAddress') &&
    localStorage.getItem('kaikasAddress').toLowerCase();

  React.useEffect(() => {
    const checkListingStatus = async () => {
      const data = {
        tricky: dino.tokenId,
        createdBy: kaikasAddress,
      };
      const response = await axios.post(`/list/check`, data);
      const result = response.data;

      if (result.latest != null && result.latest.length) {
        const latestListingDate = new Date(result.latest[0].createdAt);
        const today = new Date();
        const timeDiff = today.getTime() - latestListingDate.getTime();
        const day = 1000 * 60 * 60 * 24;
        
        if (window.klaytn.networkVersion === 1001) {
          if (
            result.latest[0].status &&
            result.latest[0]?.status != 'ACTIVE' &&
            timeDiff < 1000 * 60 * 10
          ) {
            // Testnet: 10 mins
            setPending(true);
          }
        } else {
          if (
            result.latest[0].status &&
            result.latest[0]?.status != 'ACTIVE' &&
            timeDiff < day * 10
          ) {
            // Maintnet: 10 days
            setPending(true);
          }
        }
        

        setLink(result.latest[0].link);
      }
      setListInfo(response.data.latest);
    };
    checkListingStatus();
  }, [dino, kaikasAddress, setListInfo, setPending]);

  React.useEffect(() => {
    if (listInfo != null && listInfo.length) {
      const max = 15 * 60;
      const startedAt = new Date(listInfo[0]?.expireAt);
      const interval = setInterval(() => {
        setTimeLeft(() => {
          const left = (Date.now() - startedAt) / 1000;
          if (left >= max) clearInterval(interval);
          return 0 - Math.floor(left);
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [listInfo]);

  React.useEffect(() => {
    const getHistory = async () => {
      const params = {
        tokenId: dino.tokenId,
      };
      const config = {
        params: params,
      };
      const response = await axios.get(`/transaction/history`, config);
      setHistory(response.data);
    };
    getHistory();
  }, [dino]);

  const myList = React.useCallback(async () => {
    const id = location.pathname.split('/list/')[1];
    const response = await axios.get(`/dino/details/${id}`);
    setDino(response.data);
    setAttributes(JSON.parse(response.data.metadata).attributes);
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        window.klaytn.networkVersion === 1001
          ? v1TestContractAddress
          : v1ContractAddress,
      );
    const owner = contract && (await contract.methods.ownerOf(id).call());
    setOwner(owner.toLowerCase());
    setLoading(false);
  }, [setDino, setLoading]);

  const handleExpiredLink = React.useCallback(async () => {
    if (!pending) {
      const data = {
        tricky: dino.tokenId,
        createdBy: kaikasAddress,
      };
      await axios.post(`/list/refresh`, data).then(() => {
        window.location.reload();
      });
    } else {
      toast('Please check out the time left', {
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [dino, pending, kaikasAddress]);

  const handleCreateLink = React.useCallback(async () => {
    try {
      if (Number(price) > 0 && !pending) {
        const data = {
          tricky: dino.tokenId,
          price: price,
          createdBy: kaikasAddress,
          expireAt: window.klaytn.networkVersion === 1001 ? new Date(
            new Date().getTime() + 5 * 60 * 1000,
          ).toISOString() : new Date(
            new Date().getTime() + 15 * 60 * 1000,
          ).toISOString(),
          availableAfter: new Date().toISOString(),
        };
        await axios.post(`/list/create`, data).then(response => {
          setListInfo([response.data]);
          setLink(response.data.link);
        });
      } else {
        alert('Wrong Price');
      }
    } catch (e) {
      console.log(e);
    }
  }, [dino, price, kaikasAddress]);

  React.useEffect(() => {
    myList();
  }, [myList]);

  return loading ? (
    <div style={{ margin: 'auto', width: '300px', color: 'white' }}>
      Loading...
    </div>
  ) : !loading && kaikasAddress === owner && listInfo != null ? (
    <Container>
      <Profile>
        <a
          href={`https://opensea.io/assets/klaytn/0xf556116c62203424fa0c0c659cadcdd8d9d07675/${dino.tokenId}`}
          target="_blank"
          rel="noreferrer"
          style={{ width: '100%', color: 'transparent' }}
        >
          <Image src={`${ipfsImage}${dino.tokenId}.png`} />
        </a>
        {listInfo.length && !pending ? (
          <ProfileInfo>
            <div>
              <BreedInfo>
                <BreedInfoContainer>
                  <BreedInfoTitle>Wedding Fee</BreedInfoTitle>
                  <BreedData>{listInfo[0].price} KLAY</BreedData>
                </BreedInfoContainer>
                {timeLeft > 1 ? (
                  <BreedInfoContainer style={{ marginLeft: '20px' }}>
                    <BreedInfoTitle>Time Left</BreedInfoTitle>
                    <BreedData>
                      {timeDifference(
                        new Date(listInfo[0]?.expireAt),
                        new Date(),
                      )}
                    </BreedData>
                  </BreedInfoContainer>
                ) : (
                  <BreedInfoContainer style={{ marginLeft: '20px' }}>
                    <BreedInfoTitle>Time Left</BreedInfoTitle>
                    <BreedData>Expired</BreedData>
                  </BreedInfoContainer>
                )}
              </BreedInfo>

              {timeLeft > 1 ? (
                <ProfileButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/breed/${link}`,
                    );
                    toast('Copied the link', {
                      position: 'top-center',
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  Copy Link
                </ProfileButton>
              ) : (
                <ProfileButton onClick={handleExpiredLink}>
                  Refresh
                </ProfileButton>
              )}
            </div>
          </ProfileInfo>
        ) : listInfo.length && pending ? (
          <ProfileInfo>
            <BreedInfo>
              <BreedInfoContainer>
                <BreedInfoTitle>Status</BreedInfoTitle>
                <BreedData>On maternity leave</BreedData>
              </BreedInfoContainer>
              {timeLeft > 1 ? (
                <BreedInfoContainer style={{ marginLeft: '20px' }}>
                  <BreedInfoTitle>Time Left</BreedInfoTitle>
                  <BreedData>
                    {timeDifference(
                      new Date(listInfo[0]?.expireAt),
                      new Date(),
                    )}
                  </BreedData>
                </BreedInfoContainer>
              ) : (
                <BreedInfoContainer style={{ marginLeft: '20px' }}>
                  <BreedInfoTitle>Time Left</BreedInfoTitle>
                  <BreedData>
                    {window.klaytn.networkVersion === 1001 ? timeDifferenceDate(
                      new Date(
                        new Date(listInfo[0]?.createdAt).getTime() +
                        1000 * 60 * 10
                      ),
                      new Date(),
                    ) : timeDifferenceDate(
                      new Date(
                        new Date(listInfo[0]?.createdAt).getTime() +
                          10 * 24 * 60 * 60 * 1000,
                      ),
                      new Date(),
                    )}
                  </BreedData>
                </BreedInfoContainer>
              )}
            </BreedInfo>
            <ProfileButton onClick={() => window.location.reload()}>
              Refresh
            </ProfileButton>
          </ProfileInfo>
        ) : (
          <ProfileInfo>
            <ProfileButton onClick={() => setModal(true)}>
              Create link
            </ProfileButton>
          </ProfileInfo>
        )}
      </Profile>
      <MetaData>
        <MetaDataHeader>Cryptorus V1</MetaDataHeader>
        <TokenId>Cryptorus #{dino.tokenId}</TokenId>

        <TableContainer style={{ marginBottom: '25px' }}>
          <Table>
            <TableHeader>
              <TableRow>
                <HeaderLeft>Details</HeaderLeft>
                <TableRight></TableRight>
              </TableRow>
            </TableHeader>
            <tbody>
              <TableRow>
                <TableLeft>Owner</TableLeft>
                <TableRight>{kaikasAddress}</TableRight>
              </TableRow>
              {listInfo[0] == null || listInfo[0]?.status === 'ACTIVE' ? (
                <TableRow>
                  <TableLeft>Status</TableLeft>
                  <TableRight>Looking for fiance</TableRight>
                </TableRow>
              ) : null}
              {listInfo[0]?.status != null && listInfo[0]?.status === 'DONE' ? (
                <TableRow>
                  <TableLeft>Status</TableLeft>
                  <TableRight>On maternity leave</TableRight>
                </TableRow>
              ) : null}
            </tbody>
          </Table>
        </TableContainer>

        {history != null && history?.length ? (
          <TableContainer style={{ marginBottom: '25px' }}>
            <Table>
              <TableHeader>
                <TableRow>
                  <HeaderLeft>Breeding History</HeaderLeft>
                  <TableRight></TableRight>
                </TableRow>
              </TableHeader>
              <tbody>
                {history.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableLeft>
                        <a
                          href={
                            window.klaytn.networkVersion === 1001
                              ? `https://baobab.scope.klaytn.com/tx/${item.tx_id}?tabId=nftTransfer`
                              : `https://scope.klaytn.com/tx/${item.tx_id}?tabId=nftTransfer`
                          }
                          target="_blank"
                          rel="noreferrer"
                          style={{ width: '100%', color: 'white' }}
                        >
                          {new Date(item.created_at).toLocaleString()}
                        </a>
                      </TableLeft>
                      <TableRight>
                        <a
                          href={`https://opensea.io/assets/klaytn/0xf556116c62203424fa0c0c659cadcdd8d9d07675/${item?.father}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ width: '100%', color: 'white' }}
                        >
                          Cryptorus #{item?.father}
                        </a>
                      </TableRight>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        ) : null}

        <TableContainer>
          <Table>
            <TableHeader>
              <TableRow>
                <HeaderLeft>Properties</HeaderLeft>
                <TableRight></TableRight>
              </TableRow>
            </TableHeader>
            <tbody>
              {attributes.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableLeft>{Object.values(item)[0]}</TableLeft>
                    <TableRight>{Object.values(item)[1]}</TableRight>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      </MetaData>
      {modal && !pending ? (
        <BreedOptionModal
          image={`${ipfsImage}${dino.tokenId}.png`}
          onClose={() => setModal(false)}
          onSelect={handleCreateLink}
          setPrice={setPrice}
          show={modal}
        />
      ) : null}
    </Container>
  ) : (
    <Container>
      <NotOwnerContainer>
        <NotOwner src={images.noTricky} />
        <NotOwnerText>You cannot access this listing</NotOwnerText>
      </NotOwnerContainer>
    </Container>
  );
};

export default List;
