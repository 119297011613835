import React from 'react';
import styled from 'styled-components';
import images from 'common/images';
import { Col, Row } from 'pages/SeigeItem';

const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
`;

const CampButton = styled.div`
  display: inline-block;
  margin: 10px;
  border-radius: 16px;
`;

const SeigeItemCampSelection = ({ itemTargetCamp, setItemTargetCamp }) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <Row>
        <Col>
          <CampButton
            style={
              itemTargetCamp === 1
                ? {
                    border: 'white 2px solid',
                  }
                : {
                    border: 'transparent 2px solid',
                  }
            }
            onClick={() => setItemTargetCamp('tyranno')}
          >
            <img
              src={images.tyrannoCamp}
              style={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 16,
                border:
                  itemTargetCamp === 'tyranno' ? '#ffffff 2px solid' : null,
              }}
            />
          </CampButton>
          <Title>티라노</Title>
        </Col>
        <Col>
          <CampButton
            style={
              itemTargetCamp === 2
                ? {
                    border: 'white 2px solid',
                  }
                : {
                    border: 'transparent 2px solid',
                  }
            }
            onClick={() => setItemTargetCamp('tricky')}
          >
            <img
              src={images.trickyCamp}
              style={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 16,
                border:
                  itemTargetCamp === 'tricky' ? '#ffffff 2px solid' : null,
              }}
            />
          </CampButton>
          <Title>트리키</Title>
        </Col>
        <Col>
          <CampButton
            style={
              itemTargetCamp === 3
                ? {
                    border: 'white 2px solid',
                  }
                : {
                    border: 'transparent 2px solid',
                  }
            }
            onClick={() => setItemTargetCamp('brachy')}
          >
            <img
              src={images.brachyCamp}
              style={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 16,
                border:
                  itemTargetCamp === 'brachy' ? '#ffffff 2px solid' : null,
              }}
            />
          </CampButton>
          <Title>브라키</Title>
        </Col>
        <Col>
          <CampButton
            style={
              itemTargetCamp === 4
                ? {
                    border: 'white 2px solid',
                  }
                : {
                    border: 'transparent 2px solid',
                  }
            }
            onClick={() => setItemTargetCamp('stego')}
          >
            <img
              src={images.stegoCamp}
              style={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                borderRadius: 16,
                border: itemTargetCamp === 'stego' ? '#ffffff 2px solid' : null,
              }}
            />
          </CampButton>
          <Title>스테고</Title>
        </Col>
      </Row>
    </div>
  );
};

export default SeigeItemCampSelection;

