import React from "react";
import images from "../common/images";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="catchphrase">{t("hero-punchline")}</div>
      <div className="hero-image-container">
        <img alt="hero" src={images.forest} className="hero-image" />
      </div>
    </>
  );
};

export default Hero;
