const en = {
    translation: {
        "logout": "Sign out",
        "adopt-button": "ADOPT A DINO",
        "adopt-button2": "on DEC 30.",
        "need-kaikas": "is needed",
        "send-via-kaikas": "Send via Kaikas",
        "kaikas-sign-in": "Kaikas sign in",
        "kaikas-sign-in-needed": "Check out Kaikas",
        "account-change": "Account has been changed.",
        "need-signature": "Please sign out and sign in again.",
        "hero-punchline": "Welcome to Cryptorus Land",
        "intro-title": "Pixel Dinosaur Collection",
        "intro": `After long winter of crypto, dinosaurs are reborn in pixel. \n Scientists created Cryptorus Land so that dinosaurs can live. \n Adventurers came to the land, searching for a dinosaur egg. \n After few days, an adventurer has found a pixel egg. \n Start your adventure in Cryptorus Land!`,
        "collection-title-first": "Each of the five species of dinos",
        "collection-title-second": "has their own unique traits!",
        "brachy": "Brachy",
        "brachy-description": "Defender",
        "brachy-count": "3,000 out of 10,000",
        "brachy-details": "Brachy's unique trait is defense. The government that operates Cryptorus Land wants to provide liquidity of Brachy and other dinosaurs so that it will vitalize ecosystem and fund inflow to the community.",
        "stego": "Stego",
        "stego-description": "United We Stand, Divided We Fall",
        "stego-count": "4,000 out of 10,000",
        "stego-details": "It has the highest number of objects in Cryptorus Land. The strength of a single Stego is very weak. However, it has the highest total voting power among the five species of dinos. In other words, the more they gather, the stronger the power of the Stego Kingdom will be.",
        "tricky": "Tricky",
        "tricky-description": "The Only Breeder",
        "tricky-count": "1,600 out of 10,000",
        "tricky-details": "As the only female dino in Cryptorus Land, Tricky is busy getting love from the other four species of dinos. It is the only species that can give birth to baby dinos. Once they give birth, they need to take a break for the next month.",
        "tyranno": "Tyranno",
        "tyranno-description": "Predator",
        "tyranno-count": "400 out of 10,000",
        "tyranno-details": "Tyranno, a carnivorous dino in Cryptorus Land and the top of the food chain, has strong attack power. But, there's a small number of Tyranno so it is difficult to break through if many enemies work together to defend. Tyranno has the highest voting power among the five species.",
        "ptero": "Ptero",
        "ptero-description": "Scout",
        "ptero-count": "1,000 out of 10,000",
        "ptero-details": "Ptero, the only flying species in Cryptorus Land, can later enter all dino kingdoms in a land siege. By digging for information ahead of time, you can join the team that you think will have an advantage. But once you join, you can't get out right away.",
        "phase1-title": "Grand Open",
        "phase1-description": "The day when adventurers come to Cryptorus Land for new adventures. The website and community will be activated.",
        "phase2-title": "Mint",
        "phase2-description": "It's the day when the 10,000 dinos are reborn in pixels.",
        "phase3-title": "Train",
        "phase3-description": "The longer a trainer holds their dinos, the more token rewards he/she can get.",
        "phase4-title": "Breed",
        "phase4-description": "Through breeding between Tricky and other dinos, the adventurer will receive baby dinos. Baby dinos can be adopted or nurtured to earn additional rewards.",
        "phase5-title": "Siege Assault",
        "phase5-description": "The five species of dinos decide to create a separate kingdom within Cryptorus Land. A siege to take over each other's kingdom will break out for greater rewards.",
        "teams-subtitle": "Introducing the scientists who reproduced the dinos",
        "mint-button-description": "Dinos will hatch out on Dec 30!",
        "social-subtitle": "Meet other agents in Cryptorus Land",
        "team1-intro": "I wanna create a worldview called Cryptorus Land through NFT and blockchain-based voting functions. I assign missions to other scientists developing Cryptorus Land and manage tasks.",
        "team2-intro": "I've always dreamed of a genuine blockchain project. I am in charge of managing and thinking about the big picture of Cryptorus Land so that we can grow together with users.",
        "team3-intro": "I am a software developer who loves NFTs. I believe in the constructive future that software and blockchain will create together.",
        "team4-intro": "Rawr.",
        "before-mint-title": "Coming soon",
        "before-mint-body": "See you on Dec 30",
        "connect-kaikas": "Please connect to Kaikas",
        "select-amount": "How many Cryptorus do you want to adopt?",
        "select-amount-desc": "50 KLAY per Cryptorus",
        "select-amount-kaikas": "Click a number and check out Kaikas",
        "cancel": "Cancel",
        "mint-count": 'Minted',
        "klaytnscope-link": 'Check out Klaytnscope',
        "klaytnscope-mintingTxHash": 'Minting Transaction Hash',
        "minting-failed": "Minting has been failed or canceled. Check if your Kaikas is locked and contact gm@cryptorus.land",
        "mint-alert": 'Please activate Kaikas',
        "public-sale-end": 'Public Sales Ended.',
        "adopt-now": 'Adopt Now',
        "Enter": 'Enter',
        "kaikas-no-auth": 'Please sign in with Kaikas',
        'testnet-alert': 'You are using the Testnet',
    }
}

export default en;