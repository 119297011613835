// @ts-nocheck
import images from 'common/images';
import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  kwangBoostAddress,
  seigeContractAddress,
  seigeTestContractAddress,
  ITEM_ADDRESS,
} from 'common/contracts';
import contractAbi from '../constants/contractAbi';
import { CampModal } from 'components/CampModal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ITEM_IMAGE, SEIGE_EXPIRE_AT, SEIGE_NUMBER } from 'constants/constants';
import SeigeItemCampSelection from 'components/SeigeItemCampSelection';

const SeigeItem = () => {
  const location = useLocation();
  const type = location.state.type;
  const count = location.state.count;
  const previousCamp = location.state.camp;
  const dinoCount = location.state.dinoCount;
  const previousKp = location.state.kp;
  const history = useHistory();

  const kaikasAddress = localStorage.getItem('kaikasAddress');

  const [boost, setBoost] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedBoost, setSelectedBoost] = React.useState('선택안함');
  const [selectedBoostIndex, setSelectedBoostIndex] = React.useState(null);

  const [items, setItems] = React.useState(null);
  const [itemLoading, setItemLoading] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState('선택안함');
  const [selectedItemName, setSelectedItemName] = React.useState('선택안함');
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(null);
  const [itemKp, setItemKp] = React.useState(0);

  const [camp, setCamp] = React.useState(previousCamp);
  const [modal, setModal] = React.useState(false);
  const [eligibility, setEligibility] = React.useState(false);
  const [kp, setKp] = React.useState(previousKp);

  const [itemTargetCamp, setItemTargetCamp] = React.useState(null);

  React.useEffect(() => {
    setCamp(previousCamp);
  }, [setCamp, previousCamp]);

  React.useEffect(() => {
    setKp(previousKp);
  }, [setKp, previousKp]);

  React.useEffect(() => {
    if (type === 1) setCamp('tyranno');
    else if (type === 3) setCamp('tricky');
    else if (type === 4) setCamp('brachy');
    else if (type === 5) setCamp('stego');
  }, [type, setCamp]);

  React.useEffect(() => {
    const getCampData = async () => {
      const kaikasAddress =
        localStorage.getItem('kaikasAddress') &&
        localStorage.getItem('kaikasAddress').toLowerCase();
      const data = {
        attendee: kaikasAddress,
      };
      const response = await axios.post('seige/data', data);
      setEligibility(response.data.eligibility);
    };

    getCampData();
  }, [setEligibility]);

  const _apiUrl = React.useMemo(() => {
    switch (type) {
      case 1:
        return '/dino/my-tyranno';
      case 2:
        return '/dino/my-ptero';
      case 3:
        return '/dino/my-tricky';
      case 4:
        return '/dino/my-brachy';
      case 5:
        return '/dino/my-stego';
      default:
        return '에러';
    }
  }, [type]);

  const getIpfsAssetUrl = url => {
    return `${url.split('ipfs://')[1]}`;
  };

  const myBoost = React.useCallback(async () => {
    setLoading(true);
    const data = [];
    const contract =
      window.caver &&
      new window.caver.klay.Contract(contractAbi, kwangBoostAddress);

    const total =
      contract && (await contract.methods.balanceOf(kaikasAddress).call());

    for (let i = 0; i < total; i++) {
      const nftId =
        contract &&
        (await contract.methods.tokenOfOwnerByIndex(kaikasAddress, i).call());

      const nftMetaData =
        contract && (await contract.methods.tokenURI(nftId).call());
      await fetch(
        `https://cryptorus.mypinata.cloud/ipfs/${getIpfsAssetUrl(nftMetaData)}`,
      )
        .then(res => res.json())
        .then(output => data.push(output))
        .catch(err => console.log(err));
    }
    setBoost(data);

    setLoading(false);
  }, [window, type, _apiUrl, setBoost, setLoading]);

  const myItems = React.useCallback(async () => {
    setItemLoading(true);
    const data = [];
    const contract =
      window.caver && new window.caver.klay.Contract(contractAbi, ITEM_ADDRESS);

    const total =
      contract && (await contract.methods.balanceOf(kaikasAddress).call());

    for (let i = 0; i < total; i++) {
      const nftId =
        contract &&
        (await contract.methods.tokenOfOwnerByIndex(kaikasAddress, i).call());

      const nftMetaData =
        contract && (await contract.methods.tokenURI(nftId).call());
      await fetch(
        `https://cryptorus.mypinata.cloud/ipfs/${getIpfsAssetUrl(nftMetaData)}`,
      )
        .then(res => res.json())
        .then(output => data.push(output))
        .catch(err => console.log(err));
    }
    setItems(data);

    // const params = {
    //   tokenIds: ids,
    // };
    // const config = {
    //   params: params,
    // };
    // if (ids.length) {
    //   const response = await axios.get(_apiUrl, config);
    //   setBoost(response.data);
    // } else {
    //   setBoost([]);
    // }

    setItemLoading(false);
  }, [window, type, _apiUrl, setItems, setItemLoading]);

  React.useEffect(() => {
    myBoost();
    myItems();
  }, [myBoost, myItems]);

  const _type = React.useMemo(() => {
    switch (type) {
      case 1:
        return '티라노';
      case 2:
        return '프테로';
      case 3:
        return '트리키';
      case 4:
        return '브라키';
      case 5:
        return '스테고';
      default:
        return '에러';
    }
  }, [type]);

  const _camp = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return '티라노';
      case 'tricky':
        return '트리키';
      case 'brachy':
        return '브라키';
      case 'stego':
        return '스테고';
      default:
        return '에러';
    }
  }, [camp]);

  const _campSrc = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return images.tyrannoCamp;
      case 'tricky':
        return images.trickyCamp;
      case 'brachy':
        return images.brachyCamp;
      case 'stego':
        return images.stegoCamp;
      default:
        return images.emptyCamp;
    }
  }, [camp]);

  const _campUpperCase = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return 'TYRANNO';
      case 'tricky':
        return 'TRICKY';
      case 'brachy':
        return 'BRACHY';
      case 'stego':
        return 'STEGO';
      default:
        return 'ERROR';
    }
  }, [camp]);

  const _boostKp = React.useMemo(() => {
    // console.log(selectedBoost);
    switch (selectedBoost) {
      case 'Royal':
        return 1;
      case 'Super':
        return 3;
      case 'Hero':
        return 7;
      default:
        return 0;
    }
  }, [selectedBoost]);

  React.useEffect(() => {
    if (selectedBoost === 'Royal') {
      setKp(previousKp + 1);
    } else if (selectedBoost === 'Super') {
      setKp(previousKp + 3);
    } else if (selectedBoost === 'Hero') {
      setKp(previousKp + 7);
    }
  }, [selectedBoost]);

  React.useEffect(() => {
    if (selectedItem <= 60) {
      setItemKp(1);
    } else if (selectedItem >= 61 && selectedItem <= 80) {
      setItemKp(4);
    } else if (selectedItem >= 81 && selectedItem <= 87) {
      setItemKp(15);
    }
  }, [selectedItem]);

  React.useEffect(() => {
    if (selectedItem <= 5) {
      setSelectedItemName('Ruby');
    } else if (selectedItem >= 6 && selectedItem <= 10) {
      setSelectedItemName('Sapphire');
    } else if (selectedItem >= 11 && selectedItem <= 15) {
      setSelectedItemName('Topaz');
    } else if (selectedItem >= 16 && selectedItem <= 20) {
      setSelectedItemName('Emerald');
    } else if (selectedItem >= 21 && selectedItem <= 25) {
      setSelectedItemName('Dragon Teeth');
    } else if (selectedItem >= 26 && selectedItem <= 30) {
      setSelectedItemName('Marble');
    } else if (selectedItem >= 31 && selectedItem <= 35) {
      setSelectedItemName('Steel');
    } else if (selectedItem >= 36 && selectedItem <= 40) {
      setSelectedItemName('Magic Flower');
    } else if (selectedItem >= 41 && selectedItem <= 45) {
      setSelectedItemName('Fire Stone');
    } else if (selectedItem >= 46 && selectedItem <= 50) {
      setSelectedItemName('Wind Stone');
    } else if (selectedItem >= 51 && selectedItem <= 55) {
      setSelectedItemName('Earth Stone');
    } else if (selectedItem >= 56 && selectedItem <= 60) {
      setSelectedItemName('Forest Stone');
    } else if (selectedItem >= 61 && selectedItem <= 67) {
      setSelectedItemName('Special Wande');
    } else if (selectedItem >= 68 && selectedItem <= 74) {
      setSelectedItemName('Power Shield');
    } else if (selectedItem >= 75 && selectedItem <= 80) {
      setSelectedItemName('Magic Ring');
    } else if (selectedItem >= 81 && selectedItem <= 87) {
      setSelectedItemName('Dragon Sword');
    }
  }, [selectedItem]);

  const handleParticipate = React.useCallback(async () => {
    try {
      let address = kaikasAddress;

      if (!address || address == null) {
        return false;
      }

      const data = window.caver.klay.abi.encodeFunctionCall(
        {
          // Smart contract function name
          name: 'participate',
          type: 'function',
          inputs: [
            {
              type: 'address',
              name: 'attendee',
            },
          ],
        },
        [kaikasAddress],
      );

      // console.log('kaikasAddress', kaikasAddress);
      // console.log('kaikasAddress', window.caver.utils.toPeb(String(0), 'KLAY'));
      // console.log(window.klaytn.networkVersion)
      await window.caver.klay
        .sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: address,
          // NOTE - Smart contract address
          to:
            window.klaytn.networkVersion === 1001
              ? seigeTestContractAddress
              : seigeContractAddress,
          value: window.caver.utils.toPeb(String(0), 'KLAY'),
          data,
          gas: 8000000,
        })
        .once('transactionHash', transactionHash => {
          console.log('transactionHash', transactionHash);
        })
        .once('receipt', async receipt => {
          console.log('receipt', receipt);
          const data = {
            number: SEIGE_NUMBER,
            attendee: kaikasAddress,
            camp: _campUpperCase,
            kp: kp + itemKp,
            isPtero: type === 2,
            item: selectedItem === '선택안함' ? null : selectedItem,
            itemTarget: itemTargetCamp,
          };
          const res = await axios.post(`/seige/participate`, data);
          // console.log('res', res.data);

          if (res.data === 'success') {
            history.push({
              pathname: '/seige/result',
              state: {
                camp: camp,
                type: type,
                count: dinoCount,
                kp: kp + itemKp,
              },
            });
          } else {
            toast('이미 이번 공성전에 참여하셨습니다', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .once('error', error => {
          console.log('error', error);
          toast('알 수 없는 에러가 발생했습니다', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } catch (e) {
      console.log(e);
      toast('알 수 없는 에러가 발생했습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [
    kaikasAddress,
    _campUpperCase,
    type,
    camp,
    kp,
    itemKp,
    dinoCount,
    itemTargetCamp,
    selectedItem,
  ]);

  const toolTip = React.useCallback(id => {
    if (id >= 61 && id <= 67) {
      return (
        <ToolTip top={-30}>
          상대팀 KP :{' '}
          <span style={{ fontWeight: 700, color: '#FF0000' }}>- 4.00</span>
        </ToolTip>
      );
    } else if (id >= 68 && id <= 74) {
      return (
        <ToolTip top={-30}>
          우리팀 KP :{' '}
          <span style={{ fontWeight: 700, color: '#00F2D0' }}>+ 4.00</span>
        </ToolTip>
      );
    } else if (id >= 75 && id <= 80) {
      return (
        <ToolTip top={-50}>
          <div>
            상대팀 KP :{' '}
            <span style={{ fontWeight: 700, color: '#FF0000' }}>- 2.00</span>
          </div>
          <div>
            우리팀 KP :{' '}
            <span style={{ fontWeight: 700, color: '#00F2D0' }}>+ 2.00</span>
          </div>
        </ToolTip>
      );
    }
  }, []);

  return eligibility &&
    new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 ? (
    <>
      <Root>
        <Background src={images.seizeSelect} />
        <BoxContainer>
          <Right>
            {loading ? (
              <Loading>Loading...</Loading>
            ) : (
              <div>
                <RightHeader>
                  <RightTitle>적용시킬 아이템을 선택해주세요</RightTitle>
                </RightHeader>
                <ItemTitle>크왕 부스트</ItemTitle>
                <Row>
                  <Col
                    onClick={() => {
                      setSelectedBoost('선택안함');
                      setSelectedBoostIndex(null);
                      setKp(previousKp);
                    }}
                  >
                    <div
                      key={'no-boost'}
                      style={{
                        display: 'inline-block',
                        margin: '10px',
                        border: '#353945 1px solid',
                        borderRadius: 16,
                        paddingBottom: 5,
                      }}
                    >
                      <img
                        src={images.boostNo}
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          borderRadius: 16,
                          border:
                            selectedBoost === '선택안함'
                              ? '#ffffff 2px solid'
                              : null,
                        }}
                      />
                    </div>
                  </Col>
                  {boost != null && boost?.length
                    ? boost?.map((item, index) => {
                        const tier = item.attributes.find(
                          e => e.trait_type === 'Tier',
                        ).value;
                        return (
                          <Col
                            onClick={() => {
                              setSelectedBoost(tier);
                              setSelectedBoostIndex(index);
                            }}
                          >
                            <div
                              key={index}
                              style={{
                                display: 'inline-block',
                                margin: '10px',
                                border: '#353945 1px solid',
                                borderRadius: 16,
                                paddingBottom: 5,
                              }}
                            >
                              <img
                                src={
                                  tier === 'Royal'
                                    ? images.boostRoyal
                                    : tier === 'Super'
                                    ? images.boostSuper
                                    : images.boostHero
                                }
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  cursor: 'pointer',
                                  borderRadius: 16,
                                  border:
                                    selectedBoostIndex === index
                                      ? '#ffffff 2px solid'
                                      : null,
                                }}
                              />
                              {/* <Tag>
                                <Name>Kwang Boost #{item.edition}</Name>
                              </Tag> */}
                            </div>
                          </Col>
                        );
                      })
                    : null}
                </Row>

                <ItemTitle style={{ marginTop: '40px' }}>아이템</ItemTitle>

                <Row>
                  <Col
                    onClick={() => {
                      setSelectedItem('선택안함');
                      setSelectedItemIndex(null);
                      setKp(previousKp);
                    }}
                  >
                    <div
                      key={'no-item'}
                      style={{
                        display: 'inline-block',
                        margin: '10px',
                        border: '#353945 1px solid',
                        borderRadius: 16,
                        paddingBottom: 5,
                      }}
                    >
                      <img
                        src={images.boostNo}
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          borderRadius: 16,
                          border:
                            selectedItem === '선택안함'
                              ? '#ffffff 2px solid'
                              : null,
                        }}
                      />
                    </div>
                  </Col>
                  {items != null && items?.length
                    ? items?.map((item, index) => {
                        return (
                          <Col
                            onClick={() => {
                              setSelectedItem(item.edition);
                              setSelectedItemIndex(index);
                            }}
                            style={{ position: 'relative' }}
                          >
                            {item.edition >= 61 && item.edition <= 80
                              ? toolTip(item.edition)
                              : null}
                            {item.edition >= 61 && item.edition <= 80 ? (
                              <LevelTwo>LV 2</LevelTwo>
                            ) : null}
                            <div
                              key={index}
                              style={{
                                display: 'inline-block',
                                margin: '10px',
                                borderRadius: 16,
                                paddingBottom: 5,
                              }}
                            >
                              <img
                                src={`${ITEM_IMAGE}/${item.edition}.png`}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  cursor: 'pointer',
                                  borderRadius: 16,
                                  border:
                                    selectedItemIndex === index
                                      ? '#ffffff 2px solid'
                                      : null,
                                }}
                              />
                            </div>
                          </Col>
                        );
                      })
                    : null}
                </Row>

                {(selectedItem >= 61 && selectedItem < 67) ||
                (selectedItem >= 75 && selectedItem < 80) ? (
                  <>
                    <ItemTitle style={{ marginTop: '40px' }}>
                      {selectedItem >= 61 && selectedItem <= 67
                        ? '4KP를 차감할'
                        : '2KP를 차감할'}{' '}
                      진영을 선택해 주세요
                    </ItemTitle>

                    <SeigeItemCampSelection
                      itemTargetCamp={itemTargetCamp}
                      setItemTargetCamp={setItemTargetCamp}
                    />
                  </>
                ) : null}
              </div>
            )}
          </Right>

          <Left>
            <div>
              {type === 2 ? (
                <>
                  <PteroImage
                    src={_campSrc}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setModal(true)}
                  />
                  <div>
                    {camp != null ? (
                      <>
                        <CampInfo>진영: {_camp}</CampInfo>
                      </>
                    ) : (
                      <CampInfo onClick={() => setModal(true)}>
                        진영을 선택해주세요
                      </CampInfo>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <CampImage src={_campSrc} />
                  <CampInfo>진영: {_camp}</CampInfo>
                </>
              )}

              <Border />
            </div>
            <div>
              <LeftTitle>참여 공룡</LeftTitle>
              <PointInfo>
                <LeftContent>
                  {_type} ✕ {dinoCount}
                </LeftContent>
                <LeftPoint>{parseFloat(previousKp.toFixed(4))} KP</LeftPoint>
              </PointInfo>
            </div>
            <div style={{ marginTop: '30px' }}>
              <LeftTitle>부스트</LeftTitle>
              <PointInfo>
                <LeftContent>{selectedBoost}</LeftContent>
                <LeftPoint>{_boostKp} KP</LeftPoint>
              </PointInfo>
            </div>
            <div style={{ marginTop: '30px' }}>
              <LeftTitle>아이템</LeftTitle>
              <PointInfo>
                <LeftContent>
                  {selectedItem === '선택안함' ? '선택안함' : selectedItemName}
                </LeftContent>
                <LeftPoint>{itemKp} KP</LeftPoint>
              </PointInfo>
            </div>
            <div>
              <Border />
              <PointInfo>
                <LeftTitle>최종 참가 KP</LeftTitle>
                <LeftPoint>{parseFloat((kp + itemKp).toFixed(4))} KP</LeftPoint>
              </PointInfo>
              {dinoCount !== 0 &&
              camp != null &&
              eligibility &&
              new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 ? (
                <ParticipateButton onClick={handleParticipate}>
                  출전하기
                </ParticipateButton>
              ) : (
                <DisabledButton disabled={true}>출전하기</DisabledButton>
              )}
            </div>
          </Left>
        </BoxContainer>
      </Root>
      {modal ? (
        <CampModal
          onClose={() => setModal(false)}
          onSelect={camp => setCamp(camp)}
          show={modal}
        />
      ) : null}
    </>
  ) : null;
};

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
  justify-content: center;
  display: flex;
`;

const Background = styled.img`
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  object-fit: cover;
  opacity: 0.2;
`;

const BoxContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-top: 150px;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const Right = styled.div`
  z-index: 10000;
  background-color: #23262f;
  border-radius: 12px;
  width: 840px;
  height: 80vh;
  max-height: 712px;
  margin-right: 30px;
  overflow: scroll;

  @media (max-width: 1300px) {
    width: 780px;
  }

  @media (max-width: 1200px) {
    width: 700px;
  }

  @media (max-width: 1000px) {
    padding-bottom: 100px;
  }

  @media (min-width: 768px) {
    padding: 25px 20px 20px 20px;
  }
  @media (max-width: 767px) {
    padding: 10px;
  }
`;

const Left = styled.div`
  z-index: 10000;
  background-color: #23262f;
  border-radius: 12px;
  width: 412px;
  height: 80vh;
  max-height: 712px;

  @media (max-width: 1300px) {
    width: 350px;
  }

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 50px;
  }

  @media (min-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: white;
  padding: 10px 14px;
  justify-content: space-between;
  align-items: center;
  display: flex;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 12px;
  }
`;

const Name = styled.div`
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  position: relative;
  width: 50%;
  padding: 0 10px;

  @media (max-width: 425px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

const RightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 25px;
`;

const RightTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  padding-left: 25px;
`;

const RightButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  margin-right: 10px;
  margin-top: 5px;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

const CampImage = styled.img`
  z-index: 10000;
  margin-top: 20px;
  width: 180px;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const PteroImage = styled.img`
  z-index: 10000;
  margin-top: 20px;
  width: 180px;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    border: 2px solid #ffffff;
    border-radius: 8px;
  }
`;

const Border = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #393d4c;
  width: 100%;
`;

const PointInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const LeftContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

const LeftPoint = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

const ParticipateButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 22px;
  color: white;
  margin-top: 20px;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

const DisabledButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #a9a9a9;
  cursor: not-allowed;
  padding: 14px 20px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 22px;
  color: white;
  margin-top: 20px;
`;

const Loading = styled.div`
  margin: 100px auto;
  width: 500px;
  color: white;
  text-align: center;
  font-size: 24px;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 18px;
  }
`;

const CampInfo = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const ItemTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ToolTip = styled.div`
  background: black;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  width: 120px;
  height: auto;
  padding: 5px 10px;
  position: absolute;
  top: ${props => `${props.top}px`};
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

const LevelTwo = styled.div`
  background: black;
  position: absolute;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 50px;
  top: 12px;
  right: 22px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

export default SeigeItem;

