import React from "react";
import images from "../common/images";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

const Roadmap = () => {
  const { t } = useTranslation();
  return (
    <div className="roadmap section-top">
      <Row>
        <Col lg={6} xs={12}>
          <div className="roadmap-title">Roadmap</div>
          <div className="roadmap-container">
            <div className="roadmap-phase">PHASE 1</div>
            <div className="roadmap-item">
              <div className="roadmap-item-title">{t("phase1-title")}</div>
              <div className="roadmap-item-description">
                {t("phase1-description")}
              </div>
            </div>
          </div>
          <div className="roadmap-container">
            <div className="roadmap-phase">PHASE 2</div>
            <div className="roadmap-item">
              <div className="roadmap-item-title">{t("phase2-title")}</div>
              <div className="roadmap-item-description">
                {t("phase2-description")}
              </div>
            </div>
          </div>
          <div className="roadmap-container">
            <div className="roadmap-phase">PHASE 3</div>
            <div className="roadmap-item">
              <div className="roadmap-item-title">{t("phase3-title")}</div>
              <div className="roadmap-item-description">
                {t("phase3-description")}
              </div>
            </div>
          </div>
          <div className="roadmap-container">
            <div className="roadmap-phase">PHASE 4</div>
            <div className="roadmap-item">
              <div className="roadmap-item-title">{t("phase4-title")}</div>
              <div className="roadmap-item-description">
                {t("phase4-description")}
              </div>
            </div>
          </div>
          <div className="roadmap-container">
            <div className="roadmap-phase">PHASE 5</div>
            <div className="roadmap-item">
              <div className="roadmap-item-title">{t("phase5-title")}</div>
              <div className="roadmap-item-description">
                {t("phase5-description")}
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} xs={12}>
          <div className="roadmap-image-container">
            <img alt="" className="roadmap-image" src={images.roadmap} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Roadmap;
