import images from 'common/images';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const Top = styled.div`
  padding: 24px 28px;
  display: flex;
  justify-content: space-between;
  background-color: #141416;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-color: #141416;
`;

const TopText = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
`;

const TopButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 16px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Body = styled.div`
  display: inline-flex;
  background-color: #141416;
  padding: 5px 20px;
  width: 100%;
`;

const BodyBoldText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #b1b5c3;
`;

const BodyText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #b1b5c3;
`;

const Left = styled.div`
  display: inline-block;
  width: 25%;
  margin-left: 10px;
  margin-right: 40px;
`;

const Right = styled.div`
  display: inline-block;
  width: 65%;
`;

const AmountInput = styled.div`
  display: flex;
  align-items: 'center';
`;

const Input = styled.input`
  background: #141416;
  color: #b1b5c3;
  cursor: pointer;
  outline: none;
  border: none;
  width: 160px;
  font-size: 18px;
`;

const Unit = styled.div`
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  margin-left: 15px;
`;

export const BreedOptionModal = props => {
  const [value, setValue] = React.useState(20);
  const handleClose = () => {
    props.onClose && props.onClose();
  };
  const MIN_PRICE = 25;

  const handleSelect = id => {
    props.onSelect && props.onSelect(id);
    props.onClose && props.onClose();
  };

  const handlePrice = React.useCallback(
    e => {
      props.setPrice(Number(e.target.value));
      setValue(Number(e.target.value));
    },
    [props],
  );

  const handleError = React.useCallback(() => {
    toast(`Min ${MIN_PRICE} KLAY`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  return (
    <Modal
      id="breed"
      show={props.show}
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Top>
        <TopText>Create a link for breeding</TopText>
        <TopButton onClick={handleClose}>
          <img src={images.close} style={{ width: 24, height: 24 }} />
        </TopButton>
      </Top>
      <Body>
        <Left>
          <Image src={props.image} />
        </Left>
        <Right>
          <BodyBoldText style={{ marginBottom: '20px', marginTop: '10px' }}>
            Wedding Fee
          </BodyBoldText>
          <AmountInput>
            <Input
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={handlePrice}
              placeholder={'Enter Amount '}
              type="number"
              min={MIN_PRICE}
              step="1"
            />
            <Unit>KLAY</Unit>
          </AmountInput>
          <BodyText style={{ marginTop: '25px' }}>
            1. 브리딩 금액을 입력해주세요. (최소 25 KLAY)
          </BodyText>
          <BodyText style={{ marginTop: '15px' }}>
            2. 생성된 링크는 15분간 유효하며, 해당 기간동안 금액 수정 및 취소는 불가합니다.
          </BodyText>
          <BodyText style={{ marginTop: '15px' }}>
            3. 브리딩이 완료되면 트리키는 10일간 휴식기 (maternity leave)를 갖게 됩니다. 휴식기에는 브리딩을 위한 링크 생성이 불가합니다.
          </BodyText>
          <BodyText style={{ marginTop: '15px' }}>
            4. V2는 수컷한테 전송되며, 트리키 소유자는 해당 금액의 80%를 수익으로 얻게 됩니다.
          </BodyText>
        </Right>
      </Body>

      <Modal.Footer
        style={{
          justifyContent: 'center',
          backgroundColor: '#141416',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16PX',
        }}
        className="text-center"
      >
        <Button
          id="breed-button"
          disabled={value < MIN_PRICE}
          style={{
            height: '50px',
            width: '95%',
            borderRadius: '16px',
            marginTop: '20px',
            marginBottom: '20px',
          }}
          onClick={value >= MIN_PRICE ? handleSelect : handleError}
        >
          Create a link for breeding
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

