// @ts-nocheck
import { v1ContractAddress, v1TestContractAddress } from 'common/contracts';
import React from 'react';
// import { NFTGroup } from "../components/NFTGroup";
import contractAbi from '../constants/contractAbi';

const MyNft = () => {
  const [nft, setNft] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [count, setCount] = React.useState(null);

  const [kaikasAddress, _] = React.useState(
    localStorage.getItem('kaikasAddress'),
  );

  const getIpfsAssetUrl = url => {
    return `${url.split('ipfs://')[1]}`;
  };

  const myNfts = React.useCallback(async () => {
    const id = [];
    const adds = [];
    setLoading(true);
    // const contract =
    //   window.caver &&
    //   new window.caver.klay.Contract(
    //     contractAbi,
    //     window.klaytn.networkVersion === 1001
    //       ? v1TestContractAddress
    //       : v1ContractAddress,
    //   );
    // // console.log('chain', process.env.REACT_APP_CHAIN_ID)
    // const total =
    //   contract && (await contract.methods.balanceOf(kaikasAddress).call());
    // setCount(total);
    // setLoading(false);
    for (let i = 9432; i < 9462; i++) {
      // const nftId =
      //   contract &&
      //   (await contract.methods.tokenOfOwnerByIndex(kaikasAddress, i).call());
      // console.log(i)
      id.push(i)
      adds.push('0x8Acea22702635221E4b436f9179D053c46309e10')
      // const nftMetaData =
      //   contract && (await contract.methods.tokenURI(nftId).call());
      // await fetch(
      //   `https://cryptorus.mypinata.cloud/ipfs/${getIpfsAssetUrl(nftMetaData)}`,
      // )
      //   .then(res => res.json())
      //   .then(output => setNft(prev => [...prev, output]))
      //   .catch(err => console.log(err));
    }
    console.log(id, id.length)
    console.log(adds, adds.length)
  }, [setNft, setLoading]);

  React.useEffect(() => {
    myNfts();
  }, [myNfts]);

  // console.log(nft)
  return loading ? (
    <div style={{ margin: 'auto', width: '300px', color: 'white' }}>
      Loading...
    </div>
  ) : (
    <div
      style={{
        color: 'white',
        display: 'block',
        width: 'fit-content',
        margin: 'auto',
      }}
    >
      <h2
        style={{
          margin: 'auto',
          width: '300px',
          color: 'white',
          textAlign: 'center',
        }}
      >
        My NFTs {`(${count})`}
      </h2>
      <div style={{ display: 'inline-block' }}>
        {nft.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                border: 'white 2.5px solid',
                display: 'inline-block',
                margin: '20px',
              }}
            >
              <div>{item.name}</div>

              <img
                src={`https://cryptorus.mypinata.cloud/ipfs/${getIpfsAssetUrl(
                  item.image,
                )}`}
                style={{ width: 100, height: 100 }}
              />
              <div>
                {
                  item.attributes.find(
                    attribute => attribute.trait_type === 'Species',
                  ).value
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyNft;
