import React from "react";
import images from "../common/images";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

const Teams = () => {
  const { t } = useTranslation();

  return (
    <div className="section-top teams">
      <div className="teams-title">Meet the Scientists</div>
      <div className="teams-subtitle">{t('teams-subtitle')}</div>
      <div>
        <Row>
          <Col lg={3} md={6} xs={12}>
            <div className="profiles">
              <img alt="" src={images.team1} className="profile-photo" />
              <div className="profile-item">
                <div className="profile-item-title">Ike</div>
                <div className="profile-item-role">PM</div>
                <div className="profile-item-description">{t('team1-intro')}</div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="profiles">
              <img alt="" src={images.team2} className="profile-photo" />
              <div className="profile-item">
                <div className="profile-item-title">Specter</div>
                <div className="profile-item-role">Strategy</div>
                <div className="profile-item-description">{t('team2-intro')}</div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="profiles">
              <img alt="" src={images.team3} className="profile-photo" />
              <div className="profile-item">
                <div className="profile-item-title">Jay</div>
                <div className="profile-item-role">Developer</div>
                <div className="profile-item-description">{t('team3-intro')}</div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="profiles">
              <img alt="" src={images.team4} className="profile-photo" />
              <div className="profile-item">
                <div className="profile-item-title">Lune</div>
                <div className="profile-item-role">Designer</div>
                <div className="profile-item-description">{t('team4-intro')}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Teams;
