import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import React from "react";
import {
  getKlipAddressFetcherRoutine,
  getKlipAddressRoutine,
  klipLogoutRoutine,
} from "../modules/klip";
import {
  setKaikasAddressRoutine,
  kaikasLogoutRoutine,
} from "../modules/kaikas";
import { connect } from "react-redux";
import { KaikasAdaptor } from "./Kaikas/KaikasAdaptor";
import icons from "../common/icons";
import Login from "./Login";
import langState from "../atom/langState";
import { useSetRecoilState } from "recoil";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MyNav = (props) => {
  const { t } = useTranslation();
  // const [klipAddr, setKlipAddress] = React.useState(
  //   localStorage.getItem(LOCAL_STORAGE_KEY_KLIP_ADDRESS)
  // );
  const [kaikasAddress, setKaikasAddress] = React.useState(
    localStorage.getItem("kaikasAddress")
  );
  const setLang = useSetRecoilState(langState);

  React.useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [setLang]);

  React.useEffect(() => {
    const value = props.kaikasReducer[props.setKaikasAddressRoutine.SUCCESS];
    if (value) {
      if (value.klaytnAddress) {
        if (value.klaytnAddress !== kaikasAddress) {
          setKaikasAddress(value.klaytnAddress);
          window.location.href = "/";
        }
      } else {
        setKaikasAddress("");
      }
    }
  }, [props, kaikasAddress]);

  // React.useEffect(() => {
  //   if (props.klipReducer[props.getKlipAddressFetcherRoutine.SUCCESS]) {
  //     if (
  //       props.klipReducer[props.getKlipAddressFetcherRoutine.SUCCESS]
  //         .klaytnAddress
  //     ) {
  //       setKlipAddress(
  //         props.klipReducer[props.getKlipAddressFetcherRoutine.SUCCESS]
  //           .klaytnAddress
  //       );
  //       window.location.href = "/";
  //     }
  //   }
  // }, [props]);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  React.useEffect(() => {}, []);

  return (
    <Navbar collapseOnSelect className="global-nav">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/">
            <img alt="logo" src={icons.logo} className="nav-logo" />
          </Link>
          <NavDropdown
            title={i18n.language.toUpperCase()}
            id="lang-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={() => {
                i18n.changeLanguage("en");
                setLang("en");
                localStorage.setItem("lang", "en");
              }}
            >
              EN
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                i18n.changeLanguage("ko");
                setLang("ko");
                localStorage.setItem("lang", "ko");
              }}
            >
              KO
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav className="ml-auto">
          {kaikasAddress ? (
            <NavDropdown
              title={
                typeof kaikasAddress === 'undefined' || kaikasAddress === 'undefined' ? (
                  <div className="refresh sign-in-again">Sign in again</div>
                ) : (
                  `${kaikasAddress?.substring(0, 10)}...`
                )
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="#" onClick={logout}>
                {t("logout")}
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Login />
          )}
        </Nav>
      </Navbar.Collapse>
      <KaikasAdaptor />
    </Navbar>
  );
};
export default connect(
  (state) => ({
    ...state,
    getKlipAddressRoutine,
    getKlipAddressFetcherRoutine,
    setKaikasAddressRoutine,
  }),
  {
    klipLogoutRoutineDispatcher: klipLogoutRoutine,
    setKaikasAddressRoutineDispatcher: setKaikasAddressRoutine,
    kaikasLogoutRoutineDispatcher: kaikasLogoutRoutine,
  }
)(MyNav);
