import React from "react";
import { Button, Modal } from "react-bootstrap";
import { MultilineText } from "./MultilineText";

export const MintAmount = (props) => {
  const [show, setShow] = React.useState(props.show || true);

  const handleClose = () => {
    setShow(false);
    props.onClose && props.onClose();
  };

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title
          style={{ width: "100%", marginTop: "40px" }}
          className="text-center"
        >
          <MultilineText text={props.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{ marginBottom: "30px" }}>
        {props.body && props.body.length > 0 && (
          <div style={{ marginBottom: "40px" }}>
            <div className="mint-info">{props.body}</div>
            <div className="mint-info">{props.info}</div>
          </div>
        )}
        <Button
          style={{ height: "60px", width: "80px", marginRight: "30px" }}
          onClick={() => {
            props.onConfirm(1);
            props.onClose();
            setShow(false);
          }}
        >
          1
        </Button>
        <Button
          style={{ height: "60px", width: "80px", marginRight: "30px" }}
          onClick={() => {
            props.onConfirm(5);
            props.onClose();
            setShow(false);
          }}
        >
          5
        </Button>
        <Button
          style={{ height: "60px", width: "80px", marginRight: "30px" }}
          onClick={() => {
            props.onConfirm(10);
            props.onClose();
            setShow(false);
          }}
        >
          10
        </Button>
        <Button
          style={{ height: "60px", width: "80px" }}
          onClick={() => {
            props.onConfirm(20);
            props.onClose();
            setShow(false);
          }}
        >
          20
        </Button>
      </Modal.Body>
      <button
        className="mint-cancel"
        onClick={() => {
          props.onClose();
          setShow(false);
        }}
      >
        X
      </button>
      {/* <Modal.Footer
        style={{ justifyContent: "center", marginTop:'20px', marginBottom: '30px' }}
        className="text-center"
      >
        <button
          className="mint-cancel"
          onClick={() => {
            props.onClose();
            setShow(false);
          }}
        >
          {props.cancel}
        </button>
      </Modal.Footer> */}
    </Modal>
  );
};
