import React from "react";
import { connect } from "react-redux";
import { setKaikasAddressRoutine } from "../../modules/kaikas";
import { useTranslation } from "react-i18next";

const KaikasLoginBtn = (props) => {
  const [clicked, setClicked] = React.useState(false);
  const [notInstalled, setNotInstalled] = React.useState(false);
  const [kaikasAddress, setKaikasAddress] = React.useState("");
  const { t } = useTranslation();

  const kaikasLogin = async () => {
    await window.klaytn.enable();

    const klaytnAddress = window.klaytn.selectedAddress;
    setKaikasAddress(klaytnAddress);
    props.setKaikasAddressRoutineDispatcher({ klaytnAddress });

    window.klaytn.on("accountsChanged", () => {
      const klaytnAddress = window.klaytn.selectedAddress;
      console.log("account changed!", klaytnAddress);
      setKaikasAddress(klaytnAddress);
      props.setKaikasAddressRoutineDispatcher({ klaytnAddress });
    });

    window.location.href = "/";
  };

  const onClick = () => {
    if (!window.klaytn) {
      setNotInstalled(true);
      return;
    }
    setClicked(true);

    kaikasLogin();
  };

  if (kaikasAddress && kaikasAddress?.length > 0) {
    return <p>kaikas address: {kaikasAddress}</p>;
  }

  if (notInstalled) {
    return (
      <p className="paragraph">
        <a
          className="need-kaikas"
          href="https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"
        >
          Kaikas
        </a>
        {t('need-kaikas')}
      </p>
    );
  }

  if (clicked) {
    return <p className="refresh">{t('kaikas-sign-in-needed')}</p>;
  }

  return (
    <button onClick={onClick} className="login-button">
      {t('kaikas-sign-in')}
    </button>
  );
};

export default connect(
  (state) => ({
    ...state,
    setKaikasAddressRoutine,
  }),
  {
    setKaikasAddressRoutineDispatcher: setKaikasAddressRoutine,
  }
)(KaikasLoginBtn);
