// @ts-nocheck
import React from 'react';
// import { NFTGroup } from "../components/NFTGroup";
import contractAbi from '../constants/contractAbi';

const Snapshot = () => {
  const [start, setStart] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(0);
  const [addresses, setAddresses] = React.useState(null);
  const [tokenIds, setTokenIds] = React.useState(null);
  const [initial, setInitial] = React.useState(0);
  const [end, setEnd] = React.useState(0);
  const [max, setMax] = React.useState(0);

  const getCount = React.useCallback(async () => {
    // NOTE - Smart contract address
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        '0x7a282a132b76ac6f769af037f92978a7c978a382',
      );
    const value = contract && (await contract.methods.mintingCount().call());
    value && setMax(Number(value));
  }, [setMax]);

  React.useEffect(() => {
    getCount();
  }, [getCount]);

  const downloadFile = async (data, name) => {
    const fileName = name;
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // V1 = '0xf556116c62203424fa0c0c659cadcdd8d9d07675'
  // Kwang boost: 0x7a282a132b76ac6f769af037f92978a7c978a382
  const snapShot = React.useCallback(async () => {
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        '0x7a282a132b76ac6f769af037f92978a7c978a382',
      );
    // console.log('chain', process.env.REACT_APP_CHAIN_ID)
    const tos = [];
    const ids = [];
    if (max === 0) return alert('error');
    else {
      for (let i = initial; i <= end; i++) {
        setCurrentId(i);
        const owner = contract && (await contract.methods.ownerOf(i).call());
        tos.push(owner);
        ids.push(i);
      }
      setAddresses(tos);
      setTokenIds(ids);
    }
  }, [max, initial, end, setCurrentId, setAddresses, setTokenIds]);

  React.useEffect(() => {
    if (start) {
      snapShot();
    }
  }, [snapShot, start]);

  return (
    <div
      style={{
        color: 'white',
        display: 'block',
        width: 'fit-content',
        margin: 'auto',
      }}
    >
      {start === false ? (
        <>
          Start:
          <input
            type="text"
            name="initial"
            onChange={e => setInitial(Number(e.target.value))}
          />
          <br/><br/>
          End:{' '}
          <input
            type="text"
            name="end"
            onChange={e => setEnd(Number(e.target.value))}
          />
          <br/><br/><br/>
          <button
            style={{ marginBottom: '30px' }}
            onClick={() => setStart(true)}
          >
            Start scanning
          </button>
        </>
      ) : null}
      <div>
        {currentId < max - 1 ? (
          <div>
            Scanned ID: {currentId} | Start: {initial} | End: {end} | Total: {max}
          </div>
        ) : (
          <div>Results</div>
        )}
        {addresses != null ? (
          <div style={{ width: '500px', marginTop: '80px' }}>
            addresses:
            <button
              style={{ marginLeft: '20px' }}
              onClick={() => downloadFile(addresses, 'address_by_token_id')}
            >
              Download
            </button>
          </div>
        ) : null}
        {tokenIds != null ? (
          <div style={{ width: '500px', marginTop: '80px' }}>
            tokenIds:
            <button
              style={{ marginLeft: '20px' }}
              onClick={() => downloadFile(tokenIds, 'token_ids')}
            >
              Download
            </button>
          </div>
        ) : null}
      </div>
      {/* <div style={{ width: '500px', marginTop: '80px' }}>
        AirDrop
      </div> */}
    </div>
  );
};

export default Snapshot;
