// @ts-nocheck
import images from 'common/images';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SEIGE_NUMBER, SEIGE_TITLE } from 'constants/constants';

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
  justify-content: center;
  display: flex;
`;

const Background = styled.img`
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  object-fit: cover;
  opacity: 0.2;
`;

const BoxContainer = styled.div`
  z-index: 10000;
  background-color: #23262f;
  border-radius: 12px;
  width: 50vw;
  max-width: 800px;
  max-height: 712px;
  top: 140px;
  position: absolute;

  @media (min-width: 768px) {
    padding: 20px 25px 25px 30px;
  }
  @media (max-width: 767px) {
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: white;
`;

const SeigeResult = () => {
  const location = useLocation();
  const camp = location.state.camp;
  const type = location.state.type;
  const count = location.state.count;
  const kp = location.state.kp;

  const _type = React.useMemo(() => {
    switch (type) {
      case 1:
        return '티라노';
      case 2:
        return '프테로';
      case 3:
        return '트리키';
      case 4:
        return '브라키';
      case 5:
        return '스테고';
      default:
        return '에러';
    }
  }, [type]);

  const _campSrc = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return images.tyrannoCamp;
      case 'tricky':
        return images.trickyCamp;
      case 'brachy':
        return images.brachyCamp;
      case 'stego':
        return images.stegoCamp;
      default:
        return '에러';
    }
  }, [camp]);

  const _camp = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return '티라노';
      case 'tricky':
        return '트리키';
      case 'brachy':
        return '브라키';
      case 'stego':
        return '스테고';
      default:
        return '에러';
    }
  }, [camp]);

  return (
    <Root>
      <Background src={images.seizeSelect} />
      <BoxContainer>
        <div>
          <CampImage src={_campSrc} />
          <Title>제 {`${SEIGE_NUMBER}`}회 공성전: {`${SEIGE_TITLE}`}</Title>
          <Title>참가가 완료되었습니다</Title>
          <Border />
        </div>
        <div>
          <div style={{ marginBottom: '30px' }}>
            <ResultTitle>출전 진영</ResultTitle>
            <ResultContent>{_camp}</ResultContent>
          </div>

          <ResultTitle>참여 공룡</ResultTitle>
          <PointInfo>
            <ResultContent>{_type} ✕ {count}</ResultContent>
            <ResultPoint>{parseFloat((kp).toFixed(4))} KP</ResultPoint>
          </PointInfo>
        </div>
        <div>
          <Border />
          <PointInfo>
            <ResultTitle>최종 참가 KP</ResultTitle>
            <ResultPoint>{parseFloat((kp).toFixed(4))} KP</ResultPoint>
          </PointInfo>
          <Link to={'/fortress'} style={{ textDecoration: 'none', color: 'white' }}>
            <ConfirmButton>확인</ConfirmButton>
          </Link>
        </div>
      </BoxContainer>
    </Root>
  );
};

const CampImage = styled.img`
  z-index: 10000;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 150px;
  height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const Border = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #393d4c;
  width: 100%;
`;

const PointInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ResultTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const ResultContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

const ResultPoint = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

const ConfirmButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 22px;
  color: white;
  width: 250px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-top: 30px;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

export default SeigeResult;

