import React from "react";
import { Button, Modal } from "react-bootstrap";
import { MultilineText } from "../MultilineText";

export const KaiKasModal = (props) => {
  const [show, setShow] = React.useState(props.show || true);

  const handleClose = () => {
    setShow(false);
    props.onClose && props.onClose();
  };

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title style={{ width: "100%", marginTop: '30px' }} className="text-center">
          <MultilineText text={props.title} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className="text-center"
      >
        <Button
          style={{ height: "50px", width: "320px", marginBottom: '30px' }}
          onClick={handleClose}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
