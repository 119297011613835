import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import contractAbi from '../constants/contractAbi';
import { v1ContractAddress, v1TestContractAddress } from 'common/contracts';
import axios from 'axios';
import { ipfsImage } from 'constants/constants';
import styled from 'styled-components';
import images from 'common/images';

const Top = styled.div`
  padding: 24px 28px;
  display: flex;
  justify-content: space-between;
  background-color: #141416;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-color: #141416;
`;

const TopText = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
`;

const TopButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

const GroomButton = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 16px;
  border: 1px solid #353945;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  margin-left: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  position: relative;
  width: 50%;
  padding: 0 10px;

  @media (max-width: 425px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 16px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const NoDinoContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NoDino = styled.img`
  width: 172px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const NoDinoText = styled.div`
  margin-top: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

const AdoptText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #777e90;
  margin-top: 20px;
`;

const AdoptButton = styled.a`
  border-radius: 10px;
  padding: 10px 24px;
  width: 150px;
  margin: 50px auto 10px auto;
  background-color: transparent;
  color: white;
  font-weight: 700;
  text-align: center;
  border: white 1.5px solid;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    border: #05baad 1.5px solid;
    color: #05baad;
    text-decoration: none;
  }
`;

export const BreedModal = props => {
  const [show, setShow] = React.useState(props.show || true);
  // @ts-ignore
  const [loading, setLoading] = React.useState(true);
  const [grooms, setGrooms] = React.useState([]);
  const [active, setActive] = React.useState(null);
  const kaikasAddress = localStorage.getItem('kaikasAddress');
  const [selected, setSelected] = React.useState(null);

  const handleClose = () => {
    setShow(false);
    props.onClose && props.onClose();
  };

  const handleSelect = id => {
    setShow(false);
    props.onSelect && props.onSelect(id);
    props.onClose && props.onClose();
  };

  const handleGroomSelect = (item, index) => {
    setActive(index);
    setSelected(item);
  };

  const myGrooms = React.useCallback(async () => {
    setLoading(true);
    const ids = [];
    const contract =
      // @ts-ignore
      window.caver &&
      // @ts-ignore
      new window.caver.klay.Contract(
        contractAbi,
        // @ts-ignore
        window.klaytn.networkVersion === 1001
          ? v1TestContractAddress
          : v1ContractAddress,
      );
    const total =
      contract && (await contract.methods.balanceOf(kaikasAddress).call());

    for (let i = 0; i < total; i++) {
      const nftId =
        contract &&
        (await contract.methods.tokenOfOwnerByIndex(kaikasAddress, i).call());
      ids.push(nftId);
    }
    const params = {
      tokenIds: ids,
    };
    const config = {
      params: params,
    };
    const response = await axios.get(`/dino/my-grooms`, config);
    setGrooms(response.data);
    setLoading(false);
  }, [setGrooms, setLoading]);

  React.useEffect(() => {
    myGrooms();
  }, [myGrooms]);

  return (
    <Modal
      id="breed"
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Top>
        <TopText>Select a Groom</TopText>
        <TopButton onClick={handleClose}>
          <img src={images.close} style={{ width: 24, height: 24 }} />
        </TopButton>
      </Top>
      <div style={{ display: 'inline-block' }}>
        {loading ? (
          <div
            style={{
              color: 'white',
              textAlign: 'center',
              margin: 'auto',
              fontSize: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            Loading...
          </div>
        ) : grooms?.length ? (
          <Row>
            {grooms?.map((item, index) => {
              return (
                <Col>
                  <GroomButton
                    key={index}
                    style={
                      active === index
                        ? {
                            border: 'white 2px solid',
                          }
                        : {
                            border: 'transparent 2px solid',
                          }
                    }
                    onClick={() => handleGroomSelect(item, index)}
                  >
                    <Image src={`${ipfsImage}${item.tokenId}.png`} />
                    <Title>Cryptorus #{item.tokenId}</Title>
                  </GroomButton>
                </Col>
              );
            })}
          </Row>
        ) : (
          <NoDinoContainer>
            <NoDino src={images.noTricky} />
            <NoDinoText>You don't have a Dino yet</NoDinoText>
            <AdoptText>Want to adopt Dinos?</AdoptText>
            <AdoptButton
              href={'https://opensea.io/collection/cryptorus-klay'}
              target="_blank"
            >
              Adopt Now
            </AdoptButton>
          </NoDinoContainer>
        )}
      </div>

      <Modal.Footer
        style={{ justifyContent: 'center' }}
        className="text-center"
      >
        <Button
          style={{
            height: '50px',
            width: '95%',
            borderRadius: '16px',
            fontWeight: 'bold',
          }}
          onClick={() => handleSelect(selected)}
        >
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

