// @ts-nocheck
import React from 'react';
// import { NFTGroup } from "../components/NFTGroup";
import contractAbi from '../constants/contractAbi';

const order = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 691, 672, 270, 825, 1966,
  652, 905, 1612, 324, 1301, 1641, 89, 1944, 1363, 1214, 1862, 199, 993, 527,
  688, 775, 1014, 729, 1936, 1333, 1677, 555, 149, 774, 1359, 1589, 407, 718,
  450, 1690, 1524, 169, 1907, 520, 425, 1851, 387, 1664, 283, 247, 453, 745,
  1480, 587, 546, 875, 521, 800, 702, 560, 1414, 1737, 205, 1995, 802, 595,
  1488, 1791, 1234, 1773, 1106, 1838, 282, 747, 1978, 1092, 1039, 1313, 1556,
  1079, 1074, 668, 1561, 1312, 820, 23, 395, 1531, 1557, 1928, 499, 1208, 1782,
  311, 558, 1769, 715, 201, 165, 1261, 72, 648, 803, 132, 1996, 85, 361, 1251,
  1939, 1811, 726, 846, 1576, 1707, 1035, 1401, 767, 1799, 1739, 512, 1501,
  1033, 74, 1181, 444, 507, 901, 341, 760, 1765, 212, 845, 698, 80, 1630, 1403,
  297, 52, 65, 786, 101, 631, 915, 572, 966, 1609, 1206, 1248, 1605, 1245, 1241,
  847, 918, 1756, 1721, 1426, 1872, 1830, 913, 772, 1934, 1879, 335, 472, 1103,
  1548, 1540, 254, 1326, 795, 1320, 158, 107, 174, 459, 532, 1217, 1379, 1319,
  831, 625, 197, 1661, 302, 1878, 1511, 1078, 1297, 896, 799, 1516, 1112, 1304,
  1947, 1043, 1733, 61, 334, 457, 227, 1202, 404, 1880, 1254, 1710, 693, 1252,
  1482, 1077, 1419, 245, 166, 211, 1729, 1335, 1955, 858, 1846, 564, 640, 733,
  573, 1148, 582, 973, 1550, 1494, 1684, 342, 1958, 1571, 1324, 1127, 1812, 611,
  1408, 1544, 1713, 1918, 1926, 405, 264, 284, 863, 330, 1427, 476, 1567, 1644,
  714, 796, 377, 216, 150, 1922, 1010, 1818, 761, 934, 1216, 608, 1021, 1917,
  337, 290, 1205, 1675, 790, 1513, 983, 1670, 188, 1146, 1344, 117, 1471, 1338,
  1526, 1177, 1153, 1178, 1659, 1477, 1318, 497, 836, 1708, 751, 1640, 1520,
  865, 1779, 1977, 1800, 1003, 710, 35, 353, 1663, 778, 1845, 1431, 161, 1447,
  1247, 83, 666, 108, 1399, 1758, 1843, 1604, 776, 783, 213, 798, 81, 1394, 352,
  1806, 1652, 1869, 997, 452, 58, 680, 1311, 1434, 987, 265, 1515, 1330, 110,
  713, 1651, 890, 1788, 1115, 1163, 703, 202, 1778, 792, 1738, 639, 1370, 339,
  1307, 240, 1472, 310, 615, 293, 255, 634, 1901, 950, 1479, 1147, 1491, 390,
  1358, 398, 1219, 1474, 777, 857, 1959, 1564, 1920, 1876, 782, 1197, 1314, 530,
  1645, 869, 1278, 1422, 51, 1317, 1087, 1867, 1161, 1943, 329, 144, 1064, 833,
  1633, 214, 1614, 970, 1080, 182, 1689, 1225, 1001, 424, 1874, 1174, 360, 1723,
  400, 1072, 307, 1027, 1378, 728, 1034, 259, 449, 364, 1910, 1760, 172, 1504,
  1299, 551, 616, 1410, 910, 1863, 735, 897, 543, 1348, 1519, 1055, 102, 1781,
  1816, 628, 1702, 1084, 529, 1731, 1549, 1453, 215, 1607, 1552, 888, 1226, 676,
  1196, 373, 794, 92, 1559, 1054, 1925, 1648, 804, 1151, 179, 1391, 1921, 475,
  1409, 732, 1204, 1493, 1864, 1794, 1336, 200, 1895, 1076, 359, 167, 1476,
  1672, 244, 383, 954, 1941, 903, 231, 957, 1473, 1718, 722, 416, 938, 176, 670,
  832, 1002, 886, 630, 210, 873, 1373, 705, 272, 1328, 1213, 24, 1899, 1390,
  1866, 454, 859, 742, 554, 1452, 683, 122, 1555, 1577, 882, 1727, 1847, 42,
  811, 750, 624, 1747, 1787, 1195, 1154, 367, 229, 1374, 830, 878, 493, 1793,
  721, 1246, 727, 684, 1803, 71, 1201, 1968, 1139, 440, 309, 1679, 1680, 725,
  1611, 1097, 1029, 770, 1168, 31, 250, 1906, 860, 851, 960, 1475, 374, 18, 788,
  91, 1468, 516, 155, 669, 912, 1212, 1428, 1522, 1668, 50, 793, 522, 1927,
  1687, 1755, 1289, 917, 1276, 1619, 584, 1249, 1360, 1985, 589, 1502, 842,
  1481, 346, 1280, 1757, 766, 1227, 126, 627, 1455, 746, 981, 995, 924, 1449,
  1566, 1141, 944, 1909, 420, 1194, 1700, 1180, 563, 1989, 1558, 1770, 1185, 70,
  1098, 784, 408, 1649, 1331, 38, 445, 1776, 498, 1575, 638, 1290, 1734, 1774,
  500, 82, 397, 57, 145, 547, 877, 834, 1815, 1569, 1598, 1532, 1037, 622, 1042,
  967, 462, 723, 695, 1429, 1255, 1835, 561, 1345, 604, 1032, 1591, 288, 1635,
  711, 421, 1142, 67, 322, 1763, 430, 151, 384, 1265, 1784, 707, 1126, 461, 319,
  1514, 1771, 194, 1000, 1292, 1674, 1692, 190, 969, 1070, 1790, 277, 674, 815,
  130, 737, 1186, 439, 1357, 1211, 1132, 464, 806, 483, 1223, 557, 1829, 1117,
  26, 206, 1166, 396, 1411, 575, 953, 1888, 1356, 139, 426, 1040, 263, 893,
  1547, 706, 411, 501, 612, 369, 1404, 607, 1099, 68, 1498, 856, 1433, 252,
  1919, 593, 1086, 935, 515, 1412, 1354, 1061, 115, 1341, 629, 185, 436, 1353,
  549, 1133, 1369, 1626, 1967, 287, 785, 814, 919, 220, 667, 787, 1595, 678,
  1238, 413, 567, 183, 1286, 326, 316, 246, 781, 1382, 32, 1988, 465, 1857, 943,
  1062, 1695, 1105, 927, 892, 1200, 898, 1865, 338, 694, 658, 1323, 171, 808,
  224, 1766, 923, 655, 133, 1606, 789, 313, 75, 159, 1870, 540, 138, 965, 1971,
  460, 849, 1860, 1638, 1028, 758, 1530, 908, 1671, 1424, 486, 1435, 470, 822,
  474, 116, 1898, 600, 1316, 1244, 184, 1581, 226, 1631, 1060, 1417, 1961, 1642,
  1923, 1560, 1892, 955, 1485, 1100, 664, 523, 29, 862, 844, 378, 1827, 487,
  1886, 25, 362, 739, 93, 1682, 637, 941, 456, 1724, 1916, 209, 810, 566, 1170,
  432, 471, 1496, 1421, 1012, 871, 1279, 1621, 1423, 539, 494, 925, 467, 1283,
  581, 1107, 1712, 1877, 1951, 1970, 946, 1398, 1518, 1375, 719, 142, 1949, 757,
  717, 30, 1232, 552, 1486, 1305, 773, 590, 569, 21, 1507, 1158, 1110, 1058,
  536, 977, 1813, 394, 223, 1157, 193, 1023, 1701, 1913, 1258, 632, 189, 553,
  299, 1250, 1073, 230, 1274, 1119, 1257, 495, 1364, 1120, 1808, 1389, 1588,
  1780, 15, 1229, 1639, 1448, 1454, 1528, 654, 1083, 1584, 1007, 1218, 1987,
  1228, 1339, 1948, 251, 412, 571, 996, 121, 904, 153, 588, 1499, 1362, 1897,
  633, 505, 874, 431, 1387, 1332, 1696, 123, 218, 1991, 1004, 22, 1172, 1646,
  818, 131, 1460, 119, 931, 940, 473, 1420, 1381, 1586, 443, 1662, 63, 1984,
  1753, 1634, 963, 968, 1868, 1678, 19, 881, 308, 1762, 345, 356, 16, 129, 565,
  1045, 1198, 1191, 805, 1231, 417, 550, 1697, 1902, 1315, 1932, 177, 175, 274,
  355, 208, 1686, 1361, 1810, 526, 603, 279, 446, 1144, 234, 1006, 535, 570,
  1230, 112, 20, 1535, 1881, 160, 46, 545, 682, 665, 952, 1893, 1415, 902, 1994,
  1059, 1603, 304, 1853, 838, 1525, 1075, 1572, 585, 291, 451, 1298, 1931, 1063,
  73, 1376, 1395, 1541, 1111, 1632, 248, 1069, 909, 301, 1150, 1508, 1263, 1503,
  106, 1478, 1192, 1833, 1342, 671, 1025, 659, 1703, 1372, 1053, 762, 1705,
  1900, 826, 1467, 354, 136, 403, 709, 1308, 1193, 1466, 1038, 399, 618, 1537,
  1933, 1334, 1355, 1047, 889, 1783, 458, 1859, 1854, 643, 489, 1597, 1653, 544,
  1861, 278, 1964, 696, 1011, 1785, 816, 1658, 34, 964, 1579, 932, 1534, 1840,
  716, 1775, 305, 1416, 1016, 769, 1587, 1436, 225, 1743, 1824, 916, 948, 382,
  1461, 1081, 1118, 921, 1542, 1676, 1122, 1741, 1736, 1637, 1730, 1296, 1617,
  813, 1980, 1156, 1655, 318, 854, 148, 1337, 69, 385, 619, 801, 441, 1714, 635,
  791, 1350, 478, 1388, 738, 1894, 741, 1582, 181, 1752, 433, 1821, 1975, 992,
  922, 1295, 143, 232, 1992, 1935, 1457, 1538, 1284, 455, 1946, 606, 1026, 599,
  1797, 1681, 1291, 999, 1329, 1114, 1396, 1981, 1831, 376, 690, 1102, 596,
  1104, 1462, 1294, 103, 1656, 1019, 1262, 1171, 1960, 1905, 1347, 1052, 1715,
  823, 347, 1983, 1624, 1711, 414, 1796, 1071, 1309, 195, 1965, 601, 1210, 40,
  937, 1539, 1101, 1832, 697, 164, 1368, 1209, 510, 380, 135, 1545, 797, 95, 39,
  1523, 1688, 243, 1665, 1352, 1176, 1224, 292, 509, 1974, 1125, 33, 610, 392,
  303, 1924, 1717, 275, 1495, 1443, 850, 1459, 1660, 401, 998, 381, 562, 60,
  1233, 79, 1573, 488, 613, 1487, 87, 1904, 866, 605, 1327, 976, 685, 1281,
  1725, 141, 1533, 418, 1798, 1823, 1199, 840, 1445, 1842, 956, 1543, 1751,
  1266, 266, 1628, 708, 1116, 365, 262, 485, 1952, 583, 1954, 1748, 1366, 1915,
  1517, 771, 1091, 1554, 660, 1108, 267, 1732, 118, 86, 152, 1669, 1912, 96,
  1986, 55, 370, 1346, 1600, 681, 1709, 1259, 1149, 242, 1165, 48, 491, 1235,
  1805, 1282, 105, 415, 271, 295, 646, 525, 1380, 1406, 1490, 1050, 1175, 162,
  1563, 686, 1089, 1343, 1143, 88, 749, 880, 519, 99, 1749, 594, 1022, 819, 759,
  675, 975, 1767, 1096, 1536, 559, 991, 577, 1629, 173, 273, 127, 419, 1890,
  1484, 568, 221, 1207, 1275, 1413, 1497, 1973, 980, 1509, 1152, 1458, 926, 438,
  911, 1446, 434, 1440, 986, 1699, 1836, 1135, 137, 623, 1764, 1979, 1578, 1085,
  1385, 914, 864, 1704, 1256, 651, 41, 128, 1882, 1583, 1506, 391, 236, 617,
  626, 1568, 1963, 238, 53, 186, 1500, 528, 66, 429, 1293, 951, 1654, 642, 1706,
  1188, 1437, 1041, 724, 1377, 1253, 1828, 479, 843, 701, 531, 1647, 492, 1599,
  1546, 371, 76, 1856, 1303, 146, 187, 1159, 276, 899, 327, 84, 1365, 204, 876,
  1432, 1321, 1215, 1349, 1130, 328, 994, 644, 744, 848, 1745, 147, 1885, 1849,
  1512, 59, 281, 1237, 120, 1015, 1957, 958, 62, 350, 1145, 959, 1067, 1260,
  286, 1691, 156, 1625, 1179, 614, 1940, 168, 961, 1285, 315, 1082, 1809, 348,
  1277, 1673, 513, 1182, 1273, 779, 1601, 1405, 1884, 763, 597, 1883, 170, 257,
  351, 406, 1325, 1128, 1470, 867, 673, 1439, 1124, 855, 1090, 239, 1243, 939,
  203, 1183, 1088, 1066, 1789, 1137, 852, 1164, 1666, 198, 1962, 1221, 481,
  1740, 538, 620, 298, 1140, 700, 1430, 1613, 140, 900, 389, 289, 1937, 1819,
  809, 1817, 90, 1844, 712, 514, 1131, 524, 482, 1997, 100, 1322, 895, 574,
  1698, 972, 1095, 884, 1976, 1622, 1643, 47, 1942, 1608, 1908, 974, 906, 1602,
  469, 217, 592, 388, 870, 1057, 114, 920, 580, 1616, 1044, 949, 1615, 542, 942,
  1529, 861, 332, 423, 1527, 1020, 157, 261, 1850, 134, 1873, 1693, 1590, 537,
  947, 1451, 1340, 77, 1565, 841, 1267, 463, 235, 755, 1288, 699, 37, 1627,
  1242, 1580, 649, 576, 1834, 249, 1596, 704, 44, 534, 1051, 1402, 98, 358,
  1162, 241, 984, 807, 1489, 336, 839, 1848, 1716, 598, 1650, 1574, 477, 1240,
  653, 989, 480, 312, 1300, 1929, 661, 1911, 636, 437, 1264, 1017, 578, 656,
  1173, 1505, 228, 1726, 1623, 375, 1155, 1456, 1444, 936, 1814, 442, 1852, 689,
  853, 422, 891, 1875, 1982, 1386, 1065, 754, 1657, 1269, 435, 1822, 410, 366,
  579, 1465, 1722, 548, 586, 835, 1483, 730, 233, 325, 503, 237, 1804, 447, 258,
  191, 752, 484, 1903, 1839, 824, 428, 1024, 43, 1795, 111, 907, 94, 894, 1938,
  647, 314, 256, 468, 253, 1802, 1792, 1392, 1056, 97, 1855, 979, 829, 887,
  1735, 817, 533, 1594, 1187, 883, 780, 1160, 541, 982, 1094, 113, 1302, 1896,
  1184, 1425, 1719, 1807, 1772, 1049, 349, 386, 1956, 1169, 1138, 679, 1837, 28,
  868, 333, 1287, 368, 1397, 556, 1068, 154, 650, 490, 124, 764, 1993, 1167,
  753, 971, 340, 1562, 511, 662, 692, 1121, 872, 1510, 427, 508, 748, 1351,
  1236, 1694, 1271, 518, 372, 1887, 409, 1046, 1553, 27, 1109, 930, 1871, 1129,
  196, 1464, 17, 1310, 448, 1270, 1450, 317, 163, 1998, 812, 504, 506, 743,
  1754, 1950, 1826, 1048, 54, 828, 294, 1728, 687, 1551, 1492, 1134, 1841, 1685,
  1592, 1759, 466, 663, 827, 1093, 990, 1914, 1891, 591, 1306, 1825, 502, 602,
  1189, 1620, 1442, 768, 1272, 517, 320, 260, 885, 1999, 64, 736, 1407, 1203,
  731, 945, 1570, 1383, 1953, 1031, 962, 207, 1858, 45, 1190, 300, 1441, 222,
  363, 323, 1393, 1018, 1009, 219, 645, 641, 756, 1384, 879, 1005, 1889, 1463,
  104, 1239, 1268, 306, 740, 285, 1618, 609, 1746, 78, 1820, 357, 1990, 1777,
  1720, 1418, 929, 1930, 1136, 657, 343, 1585, 1972, 821, 178, 1593, 677, 1400,
  1750, 765, 1222, 402, 1013, 321, 496, 1113, 1220, 1761, 1036, 180, 280, 1367,
  331, 1744, 978, 36, 1786, 1742, 985, 1610, 56, 621, 720, 109, 1667, 1636, 734,
  125, 928, 837, 49, 268, 1123, 269, 988, 192, 1030, 1945, 1768, 1008, 1683,
  1521, 296, 379, 1969, 1469, 393, 1801, 1438, 1371, 344, 933,
];

const V2Snapshot = () => {
  const [start, setStart] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(0);
  const [addresses, setAddresses] = React.useState(null);
  const [tokenIds, setTokenIds] = React.useState(null);
  const [initial, setInitial] = React.useState(0);
  const [end, setEnd] = React.useState(0);
  const [max, setMax] = React.useState(0);

  const getCount = React.useCallback(async () => {
    // NOTE - Smart contract address
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        '0xe920968566bc9ade3166ff080ef2db539636e5d2',
      );
    const value = contract && (await contract.methods.mintingCount().call());
    value && setMax(Number(value));
  }, [setMax]);

  React.useEffect(() => {
    getCount();
  }, [getCount]);

  const downloadFile = async (data, name) => {
    const fileName = name;
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // V1 = '0xf556116c62203424fa0c0c659cadcdd8d9d07675'
  // Kwang boost: 0x7a282a132b76ac6f769af037f92978a7c978a382
  const snapShot = React.useCallback(async () => {
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        '0xe920968566bc9ade3166ff080ef2db539636e5d2',
      );
    // console.log('chain', process.env.REACT_APP_CHAIN_ID)
    const tos = [];
    const ids = [];
    if (max === 0) return alert('error');
    else {
      for (let i = initial; i <= end; i++) {
        setCurrentId(i);
        const owner =
          contract && (await contract.methods.ownerOf(order[i]).call());
        if (owner !== '0x3e969bca271A559Fa47B251459c62CF044269976') {
          tos.push(owner);
          ids.push(3);
        }
      }
      setAddresses(tos);
      setTokenIds(ids);
    }
  }, [max, initial, end, setCurrentId, setAddresses, setTokenIds]);

  React.useEffect(() => {
    if (start) {
      snapShot();
    }
  }, [snapShot, start]);

  return (
    <div
      style={{
        color: 'white',
        display: 'block',
        width: 'fit-content',
        margin: 'auto',
      }}
    >
      {start === false ? (
        <>
          Start:
          <input
            type="text"
            name="initial"
            onChange={e => setInitial(Number(e.target.value))}
          />
          <br />
          <br />
          End:{' '}
          <input
            type="text"
            name="end"
            onChange={e => setEnd(Number(e.target.value))}
          />
          <br />
          <br />
          <br />
          <button
            style={{ marginBottom: '30px' }}
            onClick={() => setStart(true)}
          >
            Start scanning
          </button>
        </>
      ) : null}
      <div>
        {currentId < max - 1 ? (
          <div>
            Scanned ID: {currentId} | Start: {initial} | End: {end} | Total:{' '}
            {max}
          </div>
        ) : (
          <div>Results</div>
        )}
        {addresses != null ? (
          <div style={{ width: '500px', marginTop: '80px' }}>
            v2 addresses:
            <button
              style={{ marginLeft: '20px' }}
              onClick={() => downloadFile(addresses, 'v2_address_by_token_id')}
            >
              Download
            </button>
          </div>
        ) : null}
        {tokenIds != null ? (
          <div style={{ width: '500px', marginTop: '80px' }}>
            reward amount:
            <button
              style={{ marginLeft: '20px' }}
              onClick={() => downloadFile(tokenIds, 'v2_reward_amount')}
            >
              Download
            </button>
          </div>
        ) : null}
      </div>
      {/* <div style={{ width: '500px', marginTop: '80px' }}>
        AirDrop
      </div> */}
    </div>
  );
};

export default V2Snapshot;

