// @ts-nocheck
import images from 'common/images';
import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  v1TestContractAddress,
  v1ContractAddress,
  seigeContractAddress,
  seigeTestContractAddress,
} from 'common/contracts';
import contractAbi from '../constants/contractAbi';
import { ipfsImage } from 'constants/constants';
import { CampModal } from 'components/CampModal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SEIGE_EXPIRE_AT, SEIGE_NUMBER } from 'constants/constants';

const SeigeParticipate = () => {
  const location = useLocation();
  const history = useHistory();
  const [dino, setDino] = React.useState(null);
  const kaikasAddress = localStorage.getItem('kaikasAddress');
  const [count, setCount] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState(0);
  const [camp, setCamp] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [eligibility, setEligibility] = React.useState(false);
  const type = location?.state?.type || null;

  React.useEffect(() => {
    if (type === 1) setCamp('tyranno');
    else if (type === 3) setCamp('tricky');
    else if (type === 4) setCamp('brachy');
    else if (type === 5) setCamp('stego');
  }, [type, setCamp]);

  React.useEffect(() => {
    const getCampData = async () => {
      const kaikasAddress =
        localStorage.getItem('kaikasAddress') &&
        localStorage.getItem('kaikasAddress').toLowerCase();
      const data = {
        attendee: kaikasAddress,
      };
      const response = await axios.post('seige/data', data);
      setEligibility(response.data.eligibility);
    };

    getCampData();
  }, [setEligibility]);

  const _apiUrl = React.useMemo(() => {
    switch (type) {
      case 1:
        return '/dino/my-tyranno';
      case 2:
        return '/dino/my-ptero';
      case 3:
        return '/dino/my-tricky';
      case 4:
        return '/dino/my-brachy';
      case 5:
        return '/dino/my-stego';
      default:
        return '에러';
    }
  }, [type]);

  const myDinos = React.useCallback(async () => {
    setLoading(true);
    const ids = [];
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        window.klaytn.networkVersion === 1001
          ? v1TestContractAddress
          : v1ContractAddress,
      );
    const total =
      contract && (await contract.methods.balanceOf(kaikasAddress).call());

    setCount(total);

    for (let i = 0; i < total; i++) {
      const nftId =
        contract &&
        (await contract.methods.tokenOfOwnerByIndex(kaikasAddress, i).call());
      ids.push(nftId);
    }
    const params = {
      tokenIds: ids,
    };
    const config = {
      params: params,
    };
    if (ids.length) {
      const response = await axios.get(_apiUrl, config);
      setDino(response.data);
    } else {
      setDino([]);
    }

    setLoading(false);
  }, [window, type, _apiUrl, setDino, setLoading]);

  React.useEffect(() => {
    myDinos();
  }, [myDinos]);

  const _type = React.useMemo(() => {
    switch (type) {
      case 1:
        return '티라노';
      case 2:
        return '프테로';
      case 3:
        return '트리키';
      case 4:
        return '브라키';
      case 5:
        return '스테고';
      default:
        return '에러';
    }
  }, [type]);

  const _point = React.useMemo(() => {
    switch (type) {
      case 1:
        return 6.25;
      case 2:
        return 1;
      case 3:
        return 0.9375;
      case 4:
        return 0.666;
      case 5:
        return 0.75;
      default:
        return '에러';
    }
  }, [type]);

  const _camp = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return '티라노';
      case 'tricky':
        return '트리키';
      case 'brachy':
        return '브라키';
      case 'stego':
        return '스테고';
      default:
        return '에러';
    }
  }, [camp]);

  const _campSrc = React.useMemo(() => {
    switch (camp) {
      case 'tyranno':
        return images.tyrannoCamp;
      case 'tricky':
        return images.trickyCamp;
      case 'brachy':
        return images.brachyCamp;
      case 'stego':
        return images.stegoCamp;
      default:
        return images.emptyCamp;
    }
  }, [camp]);

  const handleParticipate = React.useCallback(() => {
    history.push({
      pathname: '/seige/item',
      state: {
        camp: camp,
        type: type,
        count: selected,
        kp: selected * _point,
        dinoCount: selected,
      },
    });
  }, [type, camp, selected, _point]);

  return eligibility &&
    new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 ? (
    <>
      <Root>
        <Background src={images.seizeSelect} />
        <BoxContainer>
          <Right>
            {loading ? (
              <Loading>Loading...</Loading>
            ) : !loading && count && dino.length ? (
              <div>
                <RightHeader>
                  <RightTitle>참가시킬 공룡을 선택해주세요</RightTitle>
                  <RightButton
                    onClick={() => {
                      if (selected > 0) {
                        setSelected(0);
                      } else {
                        setSelected(dino?.length);
                      }
                    }}
                  >
                    전체선택 {selected > 0 ? `(${selected})` : null}
                  </RightButton>
                </RightHeader>
                <Row>
                  {dino != null && dino?.length
                    ? dino?.map((item, index) => {
                        return (
                          <Col>
                            <div
                              key={index}
                              style={{
                                display: 'inline-block',
                                margin: '10px',
                                border: '#353945 1px solid',
                                borderRadius: 16,
                                paddingBottom: 5,
                              }}
                              to={`/list/${item.tokenId}`}
                            >
                              <img
                                src={`${ipfsImage}${item.tokenId}.png`}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: 16,
                                  border: selected
                                    ? '#ffffff 4px solid'
                                    : '#353945 4px solid',
                                }}
                              />
                              <Tag>
                                <Name>Cryptorus #{item.tokenId}</Name>
                              </Tag>
                            </div>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
            ) : (
              <div>
                <NoDinoContainer>
                  <NoDino src={images.egg} />
                  <NoDinoText>아직 {_type} 공룡이 없습니다</NoDinoText>
                  <AdoptText>{_type} 공룡을 입양할까요?</AdoptText>
                  <AdoptButton
                    href={
                      'https://opensea.io/collection/cryptorus-klay?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Species&search[stringTraits][0][values][0]=Tricky'
                    }
                    target="_blank"
                  >
                    입양하기
                  </AdoptButton>
                </NoDinoContainer>
              </div>
            )}
          </Right>
          <Left>
            <div>
              {type === 2 ? (
                <>
                  <PteroImage
                    src={_campSrc}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setModal(true)}
                  />
                  <div>
                    {camp != null ? (
                      <>
                        <CampInfo>진영: {_camp}</CampInfo>
                      </>
                    ) : (
                      <CampInfo onClick={() => setModal(true)}>
                        진영을 선택해주세요
                      </CampInfo>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <CampImage src={_campSrc} />
                  <CampInfo>진영: {_camp}</CampInfo>
                </>
              )}

              <Border />
            </div>
            <div>
              <LeftTitle>참여 공룡</LeftTitle>
              <PointInfo>
                <LeftContent>
                  {_type} ✕ {selected}
                </LeftContent>
                <LeftPoint>
                  {parseFloat((selected * _point).toFixed(4))} KP
                </LeftPoint>
              </PointInfo>
            </div>
            <div>
              <Border />
              <PointInfo>
                <LeftTitle>최종 참가 KP</LeftTitle>
                <LeftPoint>
                  {parseFloat((selected * _point).toFixed(4))} KP
                </LeftPoint>
              </PointInfo>
              {selected !== 0 &&
              camp != null &&
              eligibility &&
              new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 ? (
                <ParticipateButton onClick={handleParticipate}>
                  다음
                </ParticipateButton>
              ) : (
                <DisabledButton disabled={true}>출전하기</DisabledButton>
              )}
            </div>
          </Left>
        </BoxContainer>
      </Root>
      {modal ? (
        <CampModal
          onClose={() => setModal(false)}
          onSelect={camp => setCamp(camp)}
          show={modal}
        />
      ) : null}
    </>
  ) : null;
};

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
  justify-content: center;
  display: flex;
`;

const Background = styled.img`
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  object-fit: cover;
  opacity: 0.2;
`;

const BoxContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-top: 150px;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const Right = styled.div`
  z-index: 10000;
  background-color: #23262f;
  border-radius: 12px;
  width: 840px;
  height: 80vh;
  max-height: 712px;
  margin-right: 30px;
  overflow: scroll;

  @media (max-width: 1300px) {
    width: 780px;
  }

  @media (max-width: 1200px) {
    width: 700px;
  }

  @media (max-width: 1000px) {
    padding-bottom: 100px;
  }

  @media (min-width: 768px) {
    padding: 25px 20px 20px 20px;
  }
  @media (max-width: 767px) {
    padding: 10px;
  }
`;

const Left = styled.div`
  z-index: 10000;
  background-color: #23262f;
  border-radius: 12px;
  width: 412px;
  height: 80vh;
  max-height: 712px;

  @media (max-width: 1300px) {
    width: 350px;
  }

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 50px;
  }

  @media (min-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: white;
  padding: 10px 14px;
  justify-content: space-between;
  align-items: center;
  display: flex;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 12px;
  }
`;

const Name = styled.div`
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  position: relative;
  width: 50%;
  padding: 0 10px;

  @media (max-width: 425px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

const NoDinoContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NoDino = styled.img`
  width: 100px;
  height: 100px;
  z-index: 1000;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const NoDinoText = styled.div`
  margin-top: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

const AdoptText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #777e90;
  margin-top: 20px;
`;

const AdoptButton = styled.a`
  border-radius: 10px;
  padding: 10px 24px;
  width: 150px;
  margin: 50px auto 10px auto;
  background-color: transparent;
  color: white;
  font-weight: 700;
  text-align: center;
  border: white 1.5px solid;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    border: #05baad 1.5px solid;
    color: #05baad;
    text-decoration: none;
  }
`;

const RightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 25px;
`;

const RightTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  padding-left: 25px;
`;

const RightButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  margin-right: 10px;
  margin-top: 5px;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

const CampImage = styled.img`
  z-index: 10000;
  margin-top: 20px;
  width: 180px;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const PteroImage = styled.img`
  z-index: 10000;
  margin-top: 20px;
  width: 180px;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    border: 2px solid #ffffff;
    border-radius: 8px;
  }
`;

const Border = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #393d4c;
  width: 100%;
`;

const PointInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const LeftContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

const LeftPoint = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
`;

const ParticipateButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 22px;
  color: white;
  margin-top: 20px;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

const DisabledButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #a9a9a9;
  cursor: not-allowed;
  padding: 14px 20px;
  border-radius: 90px;
  font-weight: 700;
  font-size: 22px;
  color: white;
  margin-top: 20px;
`;

const Loading = styled.div`
  margin: 100px auto;
  width: 500px;
  color: white;
  text-align: center;
  font-size: 24px;

  @media (max-width: 425px) {
    width: 300px;
    font-size: 18px;
  }
`;

const CampInfo = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export default SeigeParticipate;

