import React from "react";
import { useTranslation } from "react-i18next";
import images from "../common/images";

const Social = () => {
  const { t } = useTranslation();

  return (
    <div className="section-top teams">
      <div className="teams-title">Community</div>
      <div className="teams-subtitle">{t('social-subtitle')}</div>
      <div className="social-container">
        <a href="https://open.kakao.com/o/gWHLjZMd" className="social-button" target="_blank" rel="noreferrer">
          <img alt="" src={images.kakaoWhite} className="social-button-icon" />
          <div className="social-button-text">Kakaotalk</div>
        </a>
        <a href="https://discord.gg/GeeuGknEsN" className="social-button" target="_blank" rel="noreferrer">
          <img alt="" src={images.discordWhite} className="social-button-icon" />
          <div className="social-button-text">Discord</div>
        </a>
        <a href="https://twitter.com/Cryptorus_Land" className="social-button" target="_blank" rel="noreferrer">
          <img alt="" src={images.twitterWhite} className="social-button-icon" />
          <div className="social-button-text">Twitter</div>
        </a>
        <a href="https://mirror.xyz/cryptorusland.eth" className="social-button" target="_blank" rel="noreferrer">
          <img alt="" src={images.mirrorWhite} className="social-button-icon" />
          <div className="social-button-text">Mirror</div>
        </a>
      </div>
    </div>
  );
};

export default Social;
